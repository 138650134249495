import React from 'react';
import { Link } from 'react-router-dom';
import "./ArrowText.css";
const ArrowText=(props)=> {
  return (
    <div>
         <div className='arrow-anchor'>
            <Link className='d-flex' to={props.headanchor}>{props.linktext}
            <div className='arrow-img d-flex justify-content-center align-items-center'>
                <span>&#8594;</span>
            </div>
            </Link>
        </div>
    </div>
  )
}
export default ArrowText;
import React from "react";
// eslint-disable-next-line
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import "./PartnershipSlider.css";
import part1 from "../../../assets/images/afm-logo-website.svg";
import part2 from "../../../assets/images/beeldmerk-sbb.svg";
import { useTranslation } from "react-i18next";

const PartnershipSlider = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line
  const options1 = {
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    loop: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    smartSpeed: 3000,
    responsive: {
      320: {
        items: 2,
      },
      768: {
        items: 3,
      },
      992: {
        items: 5,
      },
    },
  };
  // eslint-disable-next-line
  const slides = [
    {
      icon: "afm-logo-website.svg",
    },
    {
      icon: "beeldmerk-sbb.svg",
    },
    {
      icon: "afm-logo-website.svg",
    },
    {
      icon: "beeldmerk-sbb.svg",
    },
    {
      icon: "afm-logo-website.svg",
    },
    {
      icon: "beeldmerk-sbb.svg",
    },
  ];
  return (
    <div className="partnership-section">
      <div className="partnership-main-title">
        <h3>{t("partnershipTitle")}</h3>
      </div>
      <div className="partnership-sec">
        <div className="partnership-main">
          <div className="partnership-logo1">
            <a
              href="https://www.afm.nl/en/sector"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={part1} alt="logo" />
            </a>
            <h4>The Dutch Authority for the Financial Markets (AFM)</h4>
          </div>
          <div className="partnership-logo2">
            <a
              href="https://www.s-bb.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={part2} alt="logo" />
            </a>
            <h4>Samenwerkingsorganisatie Beroepsonderwijs Bedrijfsleven</h4>
          </div>
        </div>
      </div>
      {/* <div className="dragonSlider-main dragon-mar">
        <OwlCarousel className="owl-theme-review" {...options1}>
          {slides.map((slide, index) => (
            <div className="item" key={index}>
              <div className="partnership-image-item">
                <img
                  src={require(`../../../assets/images/${slide.icon}`)}
                  alt="icons"
                />
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div> */}
    </div>
  );
};

export default PartnershipSlider;

import React,{useState,useEffect} from "react";
import Headdemo from "../Subcomponents/Headdemo";
import Footer from "../Subcomponents/HeaderComponents/Footer";
import Para from "../Subcomponents/Para";
import "./Shariah.css";
import ShariahArabic from "../../assets/images/shariah-page.jpg";
import { Link } from "react-router-dom";
import ConditionComp from "../Subcomponents/ConditionComp/ConditionComp";
import PhotoSlider from "../Subcomponents/DragonSlider/PhotoSlider";
import { useTranslation } from "react-i18next";
import PrincipleHeadPara from "../Subcomponents/PrincipleHeadPara/PrincipleHeadPara";
import PdfViewer from "../Subcomponents/PdfViewer";
import ArrowBtn from "../Subcomponents/ArrowBtn/ArrowBtn";
const Shariah = () => {
    const { t,i18n } = useTranslation();
    const [fairTitle, setFairTitle] = useState(t("principleSliderTitle1"));
    const [stranglingTitle, setStranglingTitle] = useState(t("principleSliderTitle2"));
    const [mutualTitle, setMutualTitle] = useState(t("principleSliderTitle3"));
    const [leverageTitle, setLeverageTitle] = useState(t("principleSliderTitle4"));
    const [transTitle, setTransTitle] = useState(t("principleSliderTitle5"));
    const [interestTitle, setInterestTitle] = useState(t("principleSliderTitle6"));
    useEffect(() => {
        setFairTitle(t("principleSliderTitle1"));
        setStranglingTitle(t("principleSliderTitle2"));
        setMutualTitle(t("principleSliderTitle3"));
        setLeverageTitle(t("principleSliderTitle4"));
        setTransTitle(t("principleSliderTitle5"));
        setInterestTitle(t("principleSliderTitle6"));
    }, [t, i18n.language, fairTitle, stranglingTitle, mutualTitle, leverageTitle, transTitle, interestTitle]);
    const pdfUrl = process.env.PUBLIC_URL + "/shariahcertificate.pdf";
    const [showPDF, setShowPDF] = useState(false);
    const handleBtnClick = () => {
        setShowPDF(true);
        window.open(pdfUrl, "_blank");
    };
    const slides = [
        {
            icon: "fair-contract3.png",
            title: fairTitle,
        },
        {
            icon: "bad-contract1.png",
            title: stranglingTitle,
        },
        {
            icon: "Handshake4.png",
            title: mutualTitle,
        },
        {
            icon: "building2.png",
            title: leverageTitle,
        },
        {
            icon: "talking6.png",
            title: transTitle,
        },
        {
            icon: "percent5.png",
            title: interestTitle,
        },
    ];
    return (
        <>
            <ConditionComp />
            <Headdemo />
            <div className="shariah-main">
                <div className="shariah-content-flex">
                    <h3>
                        {t("shariahRegulationTitle")}
                    </h3>
                    <Para
                        para={t("shariahRegulationPara")} />
                </div>
            </div>
            <div className="shariah-slider-data">
                <PhotoSlider slides={slides} />
            </div>
            <div className="container">
                <div className="shariah-sec">
                    <div className="shariah-content3">
                        <div className="text-center shariah-content3-data">
                            <Para para={t("principleMainPara")} />
                            <h3>
                                {t("principleMainHead")}
                            </h3>
                        </div>
                        <PrincipleHeadPara
                            principleHead={t("principleTitle1")}
                            principlePara={t("principlePara1")} />
                        <PrincipleHeadPara
                            principleHead={t("principleTitle2")}
                            principlePara={t("principlePara2")} />
                        <PrincipleHeadPara
                            principleHead={t("principleTitle3")}
                            principlePara={t("principlePara3")} />
                        <PrincipleHeadPara
                            principleHead={t("principleTitle4")}
                            principlePara={t("principlePara4")} />
                        <PrincipleHeadPara
                            principleHead={t("principleTitle5")}
                            principlePara={t("principlePara5")} />
                        <div className="principle-matter-data">
                            <h5>
                                {t("principleTitle6")}
                            </h5>
                            <Para para={t("principlePara6")} />
                            <Para para={t("principleSubPara6")} />
                        </div>
                        <div className="shariah-certificate d-none">
                            <div className="shariah-certificate-content1">
                                <a href="https://shariyah.net/" target="_blank" rel="noopener noreferrer">{t("principleAnchorText") }</a>
                            </div>
                            <div className="shariah-certificate-content2">
                                <a href="https://shariyah.net/" target="_blank" rel="noopener noreferrer"><img src={ShariahArabic} alt="shariah-logo" /></a>
                            </div>
                        </div>
                        <div className="principle-certificate d-flex justify-content-center d-none">
                            <Link onClick={handleBtnClick}>
                                <ArrowBtn arrowText={t("principleSeeCertificate")} />
                            </Link>
                            {showPDF && <PdfViewer pdfUrl={pdfUrl} />}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    );
}
export default Shariah;
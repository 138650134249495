import React from "react";
import gLogo from "../../../assets/images/Artboard 95logo-1.png";
import "./ProsperInvest.css";
import { useTranslation } from "react-i18next";

const ProsperInvest = () => {
  const { t } = useTranslation();
  return (
    <div className="prosper-main">
      <div className="prosper-data">
        <div className="prosper-content1">
          <h3>{t("directProsperTitle")}</h3>
        </div>
        <div className="prosper-content2">
          <div className="prosper-app-logo">
            <img src={gLogo} alt="gLogo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProsperInvest;

import React from 'react';
import "./Dropmenu.css";
const DropMenu =(props) =>{
  return (
      <div className='dropmenu-sec  d-flex'>
        <div className='dropmenu-pic'>
            <img src={props.pictureflag} alt='drop-logo'/>
        </div>
        <div className='dropmenu-content'>
            <h3>
                {props.dropitemHead}
                <span>{props.dropitemspan}</span>
            </h3>
            <p>
                {props.dropitempara}
            </p>
        </div>
    </div>
  )
}

export default DropMenu;
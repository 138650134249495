import React from "react";
import ConditionComp from "../Subcomponents/ConditionComp/ConditionComp";
import Headdemo from "../Subcomponents/Headdemo";
import Footer from "../Subcomponents/HeaderComponents/Footer";
import Phone from "../Subcomponents/Phone";
import ProsperInvest from "../Subcomponents/ProsperInvest/ProsperInvest";
import DiscoverApp from "../Subcomponents/DiscoverApp/DiscoverApp";
import Proposition from "../Subcomponents/Proposition/Proposition";
import estimateLogo from "../../assets/images/frame3-estimate.png";
import frame2Number from "../../assets/images/frame3-number.png";
import ArrowBtn from "../Subcomponents/ArrowBtn/ArrowBtn";
import "./Frame4.css";
import Heading from "../Subcomponents/Heading";
import InterestCard from "../Subcomponents/InterestCard";
import frWhat1 from "../../assets/images/frame4-what1.png";
import frWhat2 from "../../assets/images/frame4-what2.png";
import frWhat3 from "../../assets/images/frame4-what3.png";
import Founder from "../Subcomponents/Founder";
import foundLogo from "../../assets/images/P1.png";
import NumberTextCard from "../Subcomponents/NumberTextCard/NumberTextCard";
// import { Link } from "react-router-dom";
// import PdfViewer from "../Subcomponents/PdfViewer";
import growthLogo from "../../assets/images/frame-data-img-white.png";
import bg1 from "../../assets/images/bg1-3.jpg";
import { useTranslation } from "react-i18next";

const Frame4 = () => {
  const { t } = useTranslation();
  const fundDetails = [
    { label: t("propositionFoundingYear"), value: 2023 },
    { label: t("propositionCapacity"), value: "€100M" },
    { label: t("propositionInvestment"), value: "€100.000,-" },
    { label: t("propositionManagement"), value: "1%" },
    { label: t("propositionPerformance"), value: "25% HWM" },
    { label: t("propositionPayout"), value: t("propositionPayoutDuration") },
  ];
  // const pdfUrl = process.env.PUBLIC_URL + "/shariahcertificate.pdf";
  // const [showPDF, setShowPDF] = useState(false);
  // const handleBtnClick = () => {
  //   setShowPDF(true);
  //   window.open(pdfUrl, "_blank");
  // };
  return (
    <div>
      <ConditionComp />
      <Headdemo />
      <div className="main-header-funds">
        <div className="main-header-funds-logo">
          <img src={bg1} alt="main-logo" />
          <div className="main-header-abs4">
            <img src={growthLogo} alt="logo" />
            <p>
              <span>{t("directCrypto")}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="frame4-main">
        <div className="frame4-about">
          <Heading heading={t("aboutUsCapital")} />
          <div className="frame4-about-data">
            <div className="frame4-about-content1 my-auto">
              <Founder
                founderImage={foundLogo}
                founderhead="Daoud Ahmidi"
                founderSpanText={t("daDesignation")}
              />
            </div>
            <div className="frame4-about-content2">
              <div className="about-content2-inner1">
                <NumberTextCard
                  numberTextDescription={t("daTrackRecord")}
                  numberTextNumber="+10"
                />
              </div>
              <div className="about-content2-inner2">
                <NumberTextCard
                  numberTextDescription={t("daAssets")}
                  numberTextNumber="+1 M"
                />
              </div>
              <div className="about-content2-inner3">
                <NumberTextCard
                  numberTextDescription={t("daTeam")}
                  numberTextNumber="3"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="frame4-what-we-do">
          <Heading heading={t("whatToDoTitle")} />
          <div className="what-data">
            <div className="what-content1">
              <InterestCard
                interestimg={frWhat1}
                interesthead={t("whatToDoCard1Title")}
                interestpara={t("whatToDoCard1para")}
              />
            </div>
            <div className="what-content2">
              <InterestCard
                interestimg={frWhat2}
                interesthead={t("whatToDoCard2Title")}
                interestpara={t("whatToDoCard2para")}
              />
            </div>
            <div className="what-content3">
              <InterestCard
                interestimg={frWhat3}
                interesthead={t("whatToDoCard3Title")}
                interestpara={t("whatToDoCard3para")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="frame2-proposition">
        <Proposition
          estimateLogo={estimateLogo}
          propositionEstimate={t("propositionEstimatedTitle")}
          propositionLogoNumber={frame2Number}
          propositionProfileHead1={t("lowRiskTitle")}
          propositionProfilePara1={t("lowRiskPara")}
          propositionProfileHead2={t("highRiskTitle")}
          propositionProfilePara2={t("highRiskPara")}
          propositionContent2Head={t("propositionRiskTitle")}
          keyInfo={fundDetails}
          propositionSubHead={t("propositionKeyTitle")}
          propositionPara2={t("propositionPara2")}
          propositionPara1={t("propositionPara1")}
          propositionHead={t("propositionTitle")}
        />
      </div>
      <div className="frame3-arrow text-center justify-content-center">
        <ArrowBtn arrowAnchor="/howitworks" arrowText={t("startNowTitle")} />
      </div>
      <DiscoverApp />
      <ProsperInvest />
      <Footer />
      <Phone />
    </div>
  );
};

export default Frame4;

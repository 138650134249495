import React from 'react';
import HaveQuestion from '../Subcomponents/HaveQuestion';
import Para from '../Subcomponents/Para';
import Headdemo from '../Subcomponents/Headdemo';
import Footer from '../Subcomponents/HeaderComponents/Footer';
import Phone from '../Subcomponents/Phone';
import FundingCard from './FundingCard';
import "./Invest.css";
import ConditionComp from '../Subcomponents/ConditionComp/ConditionComp';
const Invest = () => {
  return (
    <>
      <ConditionComp />
      <div className='invest-main'>
        <Headdemo />
        <div className='container'>
          <div className='invest-fund d-flex justify-content-around flex-wrap'>
            <div className='invest-fund-content1'>
              <h3>
                Direct fund investing
              </h3>
            </div>
            <div className='invest-fund-content2'>
              <Para
                para=" Unlock the potential of investing in diversified direct funds and reap the benefits. " />
              <Para
                para="Expand your portfolio's reach, mitigate market correlation, minimize fluctuations, and aim for superior 
              risk-adjusted returns. "/>
            </div>
          </div>
        </div>
        {/* <ShariahQuoteSlider
          shariahImage1={investpic}
          shariahImage2={investpic1} /> */}
        <FundingCard />
        <div className='container'>
          <HaveQuestion />
        </div>
        <Footer />
        <Phone />
      </div>
    </>
  )
}
export default Invest;
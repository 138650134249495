import React from 'react'
import Headdemo from '../Subcomponents/Headdemo';
import Footer from '../Subcomponents/HeaderComponents/Footer';
import "./Contact.css";
import ContactForms2 from './ContactForms2';
import ContactNavigation from './ContactNavigation';
import Phone from '../Subcomponents/Phone';
import ConditionComp from '../Subcomponents/ConditionComp/ConditionComp';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();
    return (
        <>
            <ConditionComp />
            <Headdemo />
            <div className='contact-sec'>
                <div className='container'>
                    <div className='contact-header' data-aos="fade-up" data-aos-duration="3000">
                        <h3>
                            {t("contactGetInTouch")}
                        </h3>
                        <p>
                            {t("contactGetText1")}
                        </p>
                        <p>
                            {t("contactGetText2")}
                        </p>
                    </div>
                    <div className='contact-content-main'>
                        <div className='contact-content-navs'>
                            <ContactNavigation />
                        </div>
                        <div className='contact-content-form' data-aos="flip-right" data-aos-duration="3000">
                            <ContactForms2 />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Phone />
        </>
    )
}
export default Contact;
import React from 'react';
import ArrowText from '../Subcomponents/ArrowText';
import HaveQuestion from '../Subcomponents/HaveQuestion';
import Headdemo from '../Subcomponents/Headdemo';
import Footer from '../Subcomponents/HeaderComponents/Footer';
import Para from '../Subcomponents/Para';
import "./Howitwork.css";
import AccordionPage from '../Subcomponents/AccordionPage';
import Phone from '../Subcomponents/Phone';
import TradingGraphs from '../TradingGraphs/TradingGraphs';
import ConditionComp from '../Subcomponents/ConditionComp/ConditionComp';
import { useTranslation } from 'react-i18next';
const HowItWorks = () => {
    const { t } = useTranslation();
    return (
        <>
            <ConditionComp />
            <div className='Howitwork-main'>
                <Headdemo />
                <div className='container'>
                    <div className='control-main d-flex justify-content-between flex-wrap'>
                        <div className='control-main-head' data-aos="zoom-out-right" data-aos-duration="3000">
                            <h3>
                                {t("workControlTitle")}
                            </h3>
                        </div>
                        <div className='control-main-content' data-aos="zoom-out-left" data-aos-duration="3000">
                            <Para
                                para={t("workControlPara1")} />
                            <Para
                                para={t("workControlPara2")} />
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='HowWorkStarted d-flex justify-content-between flex-wrap process-bottom'>
                        <div className='HowWorkStarthead'>
                            <h3>
                                {t("workStartedTitle")}
                            </h3>
                            <div className='accordion-work-how'>
                                <AccordionPage />
                            </div>
                        </div>
                        <div className='HowWorkStarthead-content' data-aos="fade-up" data-aos-duration="3000">
                            <Para
                                para={t("workStartedPara1")}/>
                            <Para
                                para={t("workStartedPara2")}/>
                            <Para
                                para={t("workStartedPara3")}/>
                            <Para
                                para={t("workStartedPara4")}/>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: t("workStartedPara5"),
                                }}>
                            </p>
                            <ArrowText
                                headanchor="/contact"
                                linktext={t("contactUsTitle")} />
                        </div>
                    </div>
                    <div className='container'>
                        <TradingGraphs />
                    </div>
                </div>
                <div className='container'>
                    <HaveQuestion />
                </div>
                <Footer />
                <Phone />
            </div>
        </>
    )
}
export default HowItWorks;
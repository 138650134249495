import { Box } from "@mui/material";
import Video from "../../assets/images/4k (6).MOV";

const Loading = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#E6E6E6",
          color: "white",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "@media (maxWidth: 720px)": {
              padding: "0 0 75% 0",
            },
          }}
        >
          <video
            muted
            autoPlay
            loop
            style={{ zIndex: 120202020 }}
            width={"100%"}
            height={"auto"}
            playsInline
            preload="metadata"
          >
            <source src={Video} type="video/mp4" alt={" "} />
            Your browser does not support the video tag.
          </video>
        </div>
      </Box>
    </>
  );
};

export default Loading;
import React from 'react';
import ContactCard from '../Subcomponents/ContactCard';
import phoneIcon from "../../assets/images/phone-icon.png";
import navigationIcon from "../../assets/images/navigation-icon.png";
import emailIcon from "../../assets/images/mail-icon.png";
import messageIcon from "../../assets/images/message-icon.png";
import "./ContactNavigation.css";
import ContactCardNav from '../Subcomponents/ContactCardNav';
import { useTranslation } from 'react-i18next';

const ContactNavigation = () => {
    const { t } = useTranslation();
    return (
        <div className='contactnav-sec'>
            <div className='contact-main'>
                <div className='contact-content' data-aos="flip-right" data-aos-duration="3000">
                    <ContactCardNav
                        contactImage={emailIcon}
                        contactCardHead={t("contactEmailText")}
                        contactCardPara={t("contactEmailPara")}
                        cardLinkContent="Info@thegrowthcapital.nl"
                        cardLink="mailto:Info@thegrowthcapital.nl" />
                </div>
                <div className='contact-content' data-aos="flip-right" data-aos-duration="3000">
                    <ContactCard
                        contactImage={messageIcon}
                        contactCardHead={t("contactLiveText")}
                        contactCardPara={t("contactLivePara")}
                        cardLinkContent={t("contactStartChat")}
                        cardLink=""
                    />
                </div>
                <div className='contact-content' data-aos="flip-right" data-aos-duration="3000">
                    <ContactCard
                        contactImage={navigationIcon}
                        contactCardHead={t("contactOfficeText")}
                        contactCardPara={t("contactOfficePara")}
                        cardLinkContent="Weena 505 3013 AL Rotterdam"
                        cardLink=""
                    />
                </div>
                <div className='contact-content' data-aos="flip-right" data-aos-duration="3000">
                    <ContactCardNav
                        contactImage={phoneIcon}
                        contactCardHead={t("contactPhoneText")}
                        contactCardPara={t("contactPhonePara")}
                        cardLinkContent="(+31) 10 800 54 30"
                        cardLink="tel:(+31) 10 800 54 30" />
                </div>
            </div>
            <div>
            </div>
        </div>
    )
}

export default ContactNavigation;
import React from "react";
import "./LatestNewsDetail.css";
import Headdemo from "../Headdemo";
import ConditionComp from "../ConditionComp/ConditionComp";
import Footer from "../HeaderComponents/Footer";
import Phone from "../Phone";
import NewsPageCompLogo from "../NewsPageComp/NewsPageCompLogo";
import NewsPageCompTitle from "../NewsPageComp/NewsPageCompTitle";
import NewsPageCompBtn from "../NewsPageComp/NewsPageCompBtn";
import NewsPageCompSubTitle from "../NewsPageComp/NewsPageCompSubTitle";
import NewsPageCompPara from "../NewsPageComp/NewsPageCompPara"; 
import n4 from "../../../assets/images/n4.png";
import NewsCompGrowthLogo from "../NewsPageComp/NewsCompGrowthLogo";
import growthLogo from "../../../assets/images/Artboard 95logo-3.png";
import { useTranslation } from "react-i18next";

const LatestNewsFour = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ConditionComp />
      <Headdemo />
      <div className="news-content-width">
        <NewsCompGrowthLogo newsGrowthLogo={growthLogo} />
        <NewsPageCompLogo newsLogo={n4} />
        <NewsPageCompTitle newsTitle={t("news4Title")} />
        <div className="latest-news-btns">
          <div className="latestnew-btn1 ">
            <NewsPageCompBtn
              newsAnchorTitle={t("newsPageButton5")}
              newsAnchor="/funds"
            />
          </div>
        </div>
        <NewsPageCompSubTitle newsSubTitle={t("newsPage4Title1")} />
        <NewsPageCompPara newsPara={t("newsPage4Para41")} />
        <NewsPageCompPara newsPara={t("newsPage4Para42")} />
        <NewsPageCompPara newsPara={t("newsPage4Para43")} />
      </div>
      <Footer />
      <Phone />
    </div>
  );
};

export default LatestNewsFour;

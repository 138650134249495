import React from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import FeatureCard from '../FeatureCard';
import { useTranslation } from 'react-i18next';
const options1 = {
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    margin: 30,
    loop: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    slideTransition: 'linear',
    smartSpeed: 2000,
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 3,
        },
        1025: {
            items: 3,
        }
    },
};
const FeatureSlider = () => {
    const { t } = useTranslation();
    return (
        <div className='featureSlider-main'>
            <OwlCarousel
                className="owl-theme-review"
                {...options1}
            >
                <div className="item">
                    <div className='featureSlider-item'>
                        <FeatureCard
                            featureHead={t("featureSlider2Title1")}
                            featurePara={t("featureSlider2Para1")} />
                    </div>
                </div>
                <div className="item">
                    <div className='featureSlider-item'>
                        <FeatureCard
                            featureHead={t("featureSlider2Title4")}
                            featurePara={t("featureSlider2Para4")} />
                    </div>
                </div>
                <div className="item">
                    <div className='featureSlider-item'>
                        <FeatureCard
                            featureHead={t("featureSlider2Title3")}
                            featurePara={t("featureSlider2Para3")} />
                    </div>
                </div>
                <div className="item">
                    <div className='featureSlider-item'>
                        <FeatureCard
                            featureHead={t("featureSlider2Title2")}
                            featurePara={t("featureSlider2Para2")} />
                    </div>
                </div>
            </OwlCarousel>
        </div>
    )
}
export default FeatureSlider;
import React from 'react';
import { Link } from 'react-router-dom';
import "./FooterList.css";

const FooterList=(props)=> {
  return (
    <div className='footer-list'>
        <h4>
            {props.foothead}
        </h4>
        <ul>
            <li>
                <Link to ={props.footurl1}>{props.footlink1}</Link>
            </li>
            <li>
                <Link to ={props.footurl2}>{props.footlink2}</Link>
            </li>
            <li>
                <Link to ={props.footurl3}>{props.footlink3}</Link>
            </li>
            <li>
                <Link to ={props.footurl4}>{props.footlink4}</Link>
            </li>
            <li>
                <Link to ={props.footurl5}>{props.footlink5}</Link>
            </li>
            <li>
                <Link to ={props.footurl6}>{props.footlink6}</Link>
            </li>
        </ul>
    </div>
  )
}
export default FooterList;
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ArrowText from '../Subcomponents/ArrowText';
import ContactForms2 from '../Contact/ContactForms2';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F2F5FA',
  border: '2px solid #5348CE',
  boxShadow: 12,
  p: 4,
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div onClick={handleOpen}>
      <ArrowText
        headanchor=""
        linktext="Start investing"/>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <ContactForms2/>
        </Box>
      </Modal>
    </div>
  );
}
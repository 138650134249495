import React from "react";
import Button from "react-bootstrap/Button";
import "./LetUSCallPopover.css";
import GetAppModal from "./GetAppModal";
import { useTranslation } from "react-i18next";

const GetAppPopover = () => {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div className="letcall-main">
      <div className="letcall-btn">
        <Button onClick={() => setModalShow(true)}>{t("getAppTitle")}</Button>
      </div>

      <GetAppModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default GetAppPopover;

import Footer from "../Subcomponents/HeaderComponents/Footer";
import Phone from "../Subcomponents/Phone";
import "./LatestNews.css";
import LatestNewsCard from "../Subcomponents/LatestNewsCard/LatestNewsCard";
import Headdemo from "../Subcomponents/Headdemo";
import { Link } from "react-router-dom";
import ConditionComp from "../Subcomponents/ConditionComp/ConditionComp";
import news1 from "../../assets/images/newsData1.png";
import news2 from "../../assets/images/newsData2.png";
import news3 from "../../assets/images/newsData3.png";
import news4 from "../../assets/images/newsData4.png";
import news5 from "../../assets/images/newsData5.png";
import news6 from "../../assets/images/newsData6.png";
import news7 from "../../assets/images/newsData7.jpeg";
import { useTranslation } from "react-i18next";

const LatestNews = () => {
  const { t } = useTranslation();
  return (
    <>
      <ConditionComp />
      <div className="mt-3">
        <Headdemo />
      </div>
      <div className="container">
        <div className="latest-news-main">
          <div className="latest-news-title">
            <h4>{t("newsHeading")}</h4>
          </div>
          <div className="latest-news-card">
            <div className="latest-news-card-content">
              <Link to={`/latest7`}>
                <LatestNewsCard
                  latestCardDate="2024-06-06"
                  latestCardTitle={t("news7Title")}
                  latestCardLogo={news7}
                />
              </Link>
            </div>
            <div className="latest-news-card-content">
              <Link to={`/latest1`}>
                <LatestNewsCard
                  latestCardDate="2024-03-22"
                  latestCardTitle={t("news1Title")}
                  latestCardLogo={news1}
                />
              </Link>
            </div>
            <div className="latest-news-card-content">
              <Link to={`/latest2`}>
                <LatestNewsCard
                  latestCardDate="2024-03-22"
                  latestCardTitle={t("news2Title")}
                  latestCardLogo={news2}
                />
              </Link>
            </div>
            <div className="latest-news-card-content">
              <Link to={`/latest3`}>
                <LatestNewsCard
                  latestCardDate="2024-03-22"
                  latestCardTitle={t("news3Title")}
                  latestCardLogo={news3}
                />
              </Link>
            </div>
            <div className="latest-news-card-content">
              <Link to={`/latest4`}>
                <LatestNewsCard
                  latestCardDate="2024-03-22"
                  latestCardTitle={t("news4Title")}
                  latestCardLogo={news4}
                />
              </Link>
            </div>
            <div className="latest-news-card-content">
              <Link to={`/latest5`}>
                <LatestNewsCard
                  latestCardDate="2024-03-22"
                  latestCardTitle={t("news5Title")}
                  latestCardLogo={news5}
                />
              </Link>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="latest-news-card-content">
              <Link to={`/latest6`}>
                <LatestNewsCard
                  latestCardDate="2024-03-22"
                  latestCardTitle={t("news6Title")}
                  latestCardLogo={news6}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Phone />
    </>
  );
};

export default LatestNews;

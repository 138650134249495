import React, { useState, useEffect } from 'react'
import HaveQuestion from '../Subcomponents/HaveQuestion';
import benefitContact from "../../assets/images/benefitContact.png";
import Para from '../Subcomponents/Para';
import "./Benefits.css";
import Headdemo from '../Subcomponents/Headdemo';
import Footer from '../Subcomponents/HeaderComponents/Footer';
import ArrowText from '../Subcomponents/ArrowText';
import FeatureSlider from '../Subcomponents/DragonSlider/FeatureSlider';
import PhotoSlider from '../Subcomponents/DragonSlider/PhotoSlider';
import Phone from '../Subcomponents/Phone';
import ConditionComp from '../Subcomponents/ConditionComp/ConditionComp';
import { useTranslation } from 'react-i18next';

const Benefits = () => {
    const { t,i18n } = useTranslation();
    const [personalTitle, setPersonalTitle] = useState(t("featureSlider1Title1"));
    const [personalPara, setPersonalPara] = useState(t("featureSlider1Para1"));
    const [compliantTitle, setCompliantTitle] = useState(t("featureSlider1Title4"));
    const [compliantPara, setCompliantPara] = useState(t("featureSlider1Para4"));
    const [diverseTitle, setDiverseTitle] = useState(t("featureSlider1Title2"));
    const [diversePara, setDiversePara] = useState(t("featureSlider1Para2"));
    const [selfTitle, setSelfTitle] = useState(t("featureSlider1Title3"));
    const [selfPara, setSelfPara] = useState(t("featureSlider1Para3"));

    useEffect(() => {
        setPersonalTitle(t("featureSlider1Title1"));
        setPersonalPara(t("featureSlider1Para1"));
        setCompliantTitle(t("featureSlider1Title4"));
        setCompliantPara(t("featureSlider1Para4"));
        setDiverseTitle(t("featureSlider1Title2"));
        setDiversePara(t("featureSlider1Para2"));
        setSelfTitle(t("featureSlider1Title3"));
        setSelfPara(t("featureSlider1Para3"));
    }, [t, i18n.language, compliantTitle, compliantPara, personalTitle, personalPara, diverseTitle, diversePara, selfTitle, selfPara]); 

    const slides = [
        {
            icon: "Half-Moon-Icon.jpg",
            title: compliantTitle,
            description: compliantPara
        },
        {
            icon: "Handshake-Icon.png",
            title: personalTitle,
            description: personalPara
        },
        {
            icon: "Plane-Icon.png",
            title: diverseTitle,
            description: diversePara
        },
        {
            icon: "Brain-Icon.png",
            title: selfTitle,
            description: selfPara
        },
    ];
    return (
        <>
            <ConditionComp />
            <div className='benefits-main'>
                <Headdemo />
                <div className='container'>
                    <div className='benefit-content'>
                        <div className='benefit-content-head text-center'>
                            <h3>
                                {t("headerFeatureTitle")}
                            </h3>
                        </div>
                    </div>
                </div>
                <PhotoSlider slides={slides} />
                <div className='container'>
                    <div className='benefit-investing d-flex justify-content-between flex-wrap'>
                        <div className='benefit-investing-head'>
                            <h3>
                                {t("shouldInvestingTitle")}
                            </h3>
                        </div>
                        <div className='benefit-investing-para'>
                            <Para
                                para={t("shouldInvestingPara1")} />
                            <Para
                                para={t("shouldInvestingPara2")} />
                            <div className='benefit-investing-arrow'>
                                <ArrowText
                                    headanchor="/contact"
                                    linktext={t("getStartedTitle")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='benefit-slider'>
                    <FeatureSlider />
                </div>
                <div className='choosing-main'>
                    <div className='container'>
                        <div className='choosing-sec d-flex justify-content-between flex-wrap'>
                            <div className='choosing-content'>
                                <h3>
                                    {t("choosingTitle")}
                                </h3>
                                <Para
                                    para={t("choosingPara1")} />
                                <Para
                                    para={t("choosingPara2")} />
                                <div className='choosing-content-arrow'>
                                    <ArrowText
                                        headanchor="/contact"
                                        linktext={t("getStartedTitle")}
                                    />
                                </div>
                            </div>
                            <div className='choosing-content2'>
                                <img src={benefitContact} alt='choosing' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <HaveQuestion />
                </div>
                <Footer />
                <Phone />
            </div>
        </>
    )
}

export default Benefits
import React from "react";
import Heading from "../Heading";
import InterestCard from "../InterestCard";
import what1 from "../../../assets/images/what1.png";
import what2 from "../../../assets/images/what2.png";
import what3 from "../../../assets/images/what3.png";
import "./WhatWeDo.css";
import { useTranslation } from "react-i18next";

const WhatWeDo = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="what-we-do">
        <Heading heading={t("whatToDoTitle")} />
        <div className="what-data">
          <div className="what-content1">
            <InterestCard
              interestimg={what1}
              interesthead={t("residentialCard1Title")}
              interestpara={t("residentialCard1Para")}
            />
          </div>
          <div className="what-content2">
            <InterestCard
              interestimg={what2}
              interesthead={t("residentialCard2Title")}
              interestpara={t("residentialCard2Para")}
            />
          </div>
          <div className="what-content3">
            <InterestCard
              interestimg={what3}
              interesthead={t("residentialCard3Title")}
              interestpara={t("residentialCard3Para")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;

import Modal from "react-bootstrap/Modal";
import siteMobileLogo from "../../assets/images/logo.png";
import "./popoverstyle.css";
import { useTranslation } from "react-i18next";
const GetAppModal = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="popover-pad getapp-modal">
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter">
                      Contact us
                  </Modal.Title> */}
          <div className="popover-img">
            <img src={siteMobileLogo} alt="popover-logo" />
          </div>
        </Modal.Header>
        <h4 className="text-center mt-3">{t("comingTitle")}</h4>
      </div>
    </Modal>
  );
};
export default GetAppModal;

import React from 'react'
import PopoverModal from './PopoverModal';
import Button from 'react-bootstrap/Button';
import "./LetUSCallPopover.css";
import { useTranslation } from 'react-i18next';

const LetUSCallPopoverFooter = () => {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div className='letcall-main-foot'>
      <div className='letcall-btn-foot'>
        <Button onClick={() => setModalShow(true)}>
          <h4 className='font-bold' onClick={() => setModalShow(true)}>
            {t("headerLetTitle")}
          </h4>
        </Button>
      </div>
      <PopoverModal
        show={modalShow}
        onHide={() => setModalShow(false)} />
    </div>
  )
}

export default LetUSCallPopoverFooter;
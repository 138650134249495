import React from 'react';
import { Link } from 'react-router-dom';
import "./NewsStyles.css";

const NewsPageCompBtn = (props) => {
  return (
    <div className="news-btn">
      <Link to={props.newsAnchor}>
        <span>{props.newsAnchorTitle}</span>
      </Link>
    </div>
  );
}

export default NewsPageCompBtn
import React from 'react';
import "./FeatureCard.css";

const FeatureCard=(props)=> {
  return (
    <div className='featureCard-main'>
        <div className='featureCard-content'>
            <h3>
                {props.featureHead}
            </h3>
            <p>
                {props.featurePara}
            </p>
        </div>
    </div>
  )
}

export default FeatureCard;
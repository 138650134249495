import React from 'react';
import "./PowerMain.css";
import InterestCard from '../InterestCard';
import ie3 from "../../../assets/images/i3.png";
import ie2 from "../../../assets/images/i2.png";
import ie1 from "../../../assets/images/i1.png";
import ie4 from "../../../assets/images/i4.png";
import { useTranslation } from 'react-i18next';

const PowerMain = () => {
    const { t } = useTranslation(); 
    return (
        <div className='container'>
            <div className='powermain-sec'>
                <div className='powermain-content d-flex justify-content-between flex-wrap'>
                    <div className='powermain-content1' data-aos="fade-right" data-aos-duration="3500">
                        <InterestCard
                            interestimg={ie1}
                            interesthead={t("powerTitle1")}
                            interestpara={t("powerTitle1Para")}
                        />
                    </div>
                    <div className='powermain-content1' data-aos="zoom-in-left" data-aos-duration="3500">
                        <InterestCard
                            interestimg={ie2}
                            interesthead={t("powerTitle2")}
                            interestpara={t("powerTitle2Para")} />
                    </div>
                    <div className='powermain-content1' data-aos="fade-right" data-aos-duration="3500">
                        <InterestCard
                            interestimg={ie3}
                            interesthead={t("powerTitle3")}
                            interestpara={t("powerTitle3Para")}
                        />
                    </div>
                    <div className='powermain-content1' data-aos="zoom-in-left" data-aos-duration="3500">
                        <InterestCard
                            interestimg={ie4}
                            interesthead={t("powerTitle4")}
                            interestpara={t("powerTitle4Para")}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PowerMain;
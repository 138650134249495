import React from "react";
import Headdemo from "../Subcomponents/Headdemo";
import Footer from "../Subcomponents/HeaderComponents/Footer";
import "./PrivacyPolicy.css";
import RegisterationCode from "../RegisterationCode/RegisterationCode";
import ConditionComp from "../Subcomponents/ConditionComp/ConditionComp";

const PrivacyPolicy = () => {
  return (
    <>
      <ConditionComp />
      <div>
        <Headdemo />
        <div className="container">
          <div className="privacy-content">
            <h3>PRIVACY POLICY</h3>
            <h6>Last updated November 07, 2023</h6>
            <p>
              This privacy notice for The Growth Capital{" "}
              <b>("we," "us," or "our")</b>, describes how and why we might
              collect, store, use, and/or share <b>("process")</b> your
              information when you use our services <b>("Services")</b>, such as
              when you:
            </p>
            <ul>
              <li>
                Visit our website at{" "}
                <a href="http://www.thegrowthcapital.nl">
                  http://www.thegrowthcapital.nl
                </a>
                , or any website of ours that links to this privacy notice
              </li>
              <li>
                Engage with us in other related ways, including any sales,
                marketing, or events
              </li>
            </ul>
            <p>
              <b>Questions or concerns?</b> Reading this privacy notice will
              help you understand your privacy rights and choices. If you do not
              agree with our policies and practices, please do not use our
              Services. If you still have any questions or concerns, please
              contact us at Info@thegrowthcapital.nl.
            </p>
            <div className="summary-keypoints">
              <h4>SUMMARY OF KEY POINTS</h4>
              <p>
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our{" "}
                <span>table of contents</span> below to find the section you are
                looking for.
              </p>
              <p>
                <b>What personal information do we process?</b> When you visit,
                use, or navigate our Services, we may process personal
                information depending on how you interact with us and the
                Services, the choices you make, and the products and features
                you use. Learn more about
                <span> personal information you disclose to us.</span>
              </p>
              <p>
                <b>Do we process any sensitive personal information?</b> We do
                not process sensitive personal information.
              </p>
              <p>
                <b>Do we receive any information from third parties?</b> We do
                not receive any information from third parties.
              </p>
              <p>
                <b>How do we process your information?</b> We process your
                information to provide, improve, and administer our Services,
                communicate with you, for security and fraud prevention, and to
                comply with law. We may also process your information for other
                purposes with your consent. We process your information only
                when we have a valid legal reason to do so. Learn more about{" "}
                <span>how we process your information.</span>
              </p>
              <p>
                <b>
                  In what situations and with which parties do we share personal
                  information?
                </b>{" "}
                We may share information in specific situations and with
                specific third parties. Learn more about{" "}
                <span>
                  when and with whom we share your personal information.
                </span>
              </p>
              <p>
                <b>How do we keep your information safe? </b> We have
                organizational and technical processes and procedures in place
                to protect your personal information. However, no electronic
                transmission over the internet or information storage technology
                can be guaranteed to be 100% secure, so we cannot promise or
                guarantee that hackers, cybercriminals, or other unauthorized
                third parties will not be able to defeat our security and
                improperly collect, access, steal, or modify your information.
                Learn more about <span>how we keep your information safe.</span>
              </p>
              <p>
                <b>What are your rights? </b>Depending on where you are located
                geographically, the applicable privacy law may mean you have
                certain rights regarding your personal information. Learn more
                about<span>your privacy rights.</span>
              </p>
              <p id="aboutSection">
                <b>How do you exercise your rights? </b>The easiest way to
                exercise your rights is by submitting a
                <span>data subject access request,</span> or by contacting us.
                We will consider and act upon any request in accordance with
                applicable data protection laws.
              </p>
              <p id="aboutSection">
                Want to learn more about what we do with any information we
                collect?<span> Review the privacy notice in full.</span> or by
                contacting us. We will consider and act upon any request in
                accordance with applicable data protection laws.
              </p>
            </div>
            <div className="table-content" id="tableofcontent">
              <h4>TABLE OF CONTENTS</h4>
              <ol>
                <li>
                  <span>WHAT INFORMATION DO WE COLLECT?</span>
                </li>
                <li>
                  <span>HOW DO WE PROCESS YOUR INFORMATION?</span>
                </li>
                <li>
                  <span>
                    WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                    INFORMATION?
                  </span>
                </li>
                <li>
                  <span>
                    WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </span>
                </li>
                <li>
                  <span>HOW LONG DO WE KEEP YOUR INFORMATION?</span>
                </li>
                <li>
                  <span>HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
                </li>
                <li>
                  <span>DO WE COLLECT INFORMATION FROM MINORS?</span>
                </li>
                <li>
                  <span>WHAT ARE YOUR PRIVACY RIGHTS?</span>
                </li>
                <li>
                  <span>CONTROLS FOR DO-NOT-TRACK FEATURES</span>
                </li>
                <li>
                  <span>DO WE MAKE UPDATES TO THIS NOTICE?</span>
                </li>
                <li>
                  <span>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
                </li>
                <li>
                  <span>
                    HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                    FROM YOU?
                  </span>
                </li>
              </ol>
            </div>
            <div className="information-do">
              <h4>1. WHAT INFORMATION DO WE COLLECT?</h4>
              <h5>Personal information you disclose to us</h5>
              <p>
                <b>In Short: </b>We collect personal information that you
                provide to us
              </p>
              <p>
                We collect personal information that you voluntarily provide to
                us when you register on the Services, express an interest in
                obtaining information about us or our products and Services,
                when you participate in activities on the Services, or otherwise
                when you contact us.
              </p>
              <p>
                <b>Personal Information Provided by You. </b>The personal
                information that we collect depends on the context of your
                interactions with us and the Services, the choices you make, and
                the products and features you use. The personal information we
                collect may include the following
              </p>
              <ul>
                <li>names</li>
                <li>email addresses</li>
                <li>phone numbers</li>
                <li>usernames</li>
                <li>billing addresses</li>
              </ul>
              <p>
                <b>Sensitive Information</b> We do not process sensitive
                information.
              </p>
              <p>
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </p>
            </div>
            <div className="do-we-process">
              <h4>2. HOW DO WE PROCESS YOUR INFORMATION?</h4>
              <p>
                <b>In Short:</b> We process your information to provide,
                improve, and administer our Services, communicate with you, for
                security and fraud prevention, and to comply with law. We may
                also process your information for other purposes with your
                consent.
              </p>
              <p>
                <b>
                  We process your personal information for a variety of reasons,
                  depending on how you interact with our Services, including:
                </b>
              </p>
              <ul>
                <li>
                  <b>
                    To facilitate account creation and authentication and
                    otherwise manage user accounts.
                  </b>
                  We may process your information so you can create and log in
                  to your account, as well as keep your account in working
                  order.
                </li>
                <li>
                  <b>To save or protect an individual's vital interest.</b> We
                  may process your information when necessary to save or protect
                  an individual’s vital interest, such as to prevent harm.
                </li>
              </ul>
            </div>
            <div className="legal-basis">
              <h4>
                3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
              </h4>
              <p>
                <b>In Short:</b> We only process your personal information when
                we believe it is necessary and we have a valid legal reason
                (i.e., legal basis) to do so under applicable law, like with
                your consent, to comply with laws, to provide you with services
                to enter into or fulfill our contractual obligations, to protect
                your rights, or to fulfill our legitimate business interests.
              </p>
              <p>
                The General Data Protection Regulation (GDPR) and UK GDPR
                require us to explain the valid legal bases we rely on in order
                to process your personal information. As such, we may rely on
                the following legal bases to process your personal information:
              </p>
              <ul>
                <li>
                  <b>Consent.</b> We may process your information if you have
                  given us permission (i.e., consent) to use your personal
                  information for a specific purpose. You can withdraw your
                  consent at any time. Learn more about
                  <span>withdrawing your consent.</span>
                </li>
                <li>
                  <b>Legal Obligations.</b> We may process your information
                  where we believe it is necessary for compliance with our legal
                  obligations, such as to cooperate with a law enforcement body
                  or regulatory agency, exercise or defend our legal rights, or
                  disclose your information as evidence in litigation in which
                  we are involved.
                </li>
                <li>
                  <b>Vital Interests.</b> We may process your information where
                  we believe it is necessary to protect your vital interests or
                  the vital interests of a third party, such as situations
                  involving potential threats to the safety of any person.
                </li>
              </ul>
            </div>
            <div className="do-we-keep">
              <h4>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
              <p>
                <b>In Short:</b> We keep your information for as long as
                necessary to fulfill the purposes outlined in this privacy
                notice unless otherwise required by law
              </p>
              <p>
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy notice,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting, or other legal requirements). No
                purpose in this notice will require us keeping your personal
                information for longer than the period of time in which users
                have an account with us.
              </p>
              <p>
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
              </p>
            </div>
            <div className="collect-info">
              <h4>7. DO WE COLLECT INFORMATION FROM MINORS?</h4>
              <p>
                <b>In Short: </b>We do not knowingly collect data from or market
                to children under 18 years of age
              </p>
              <p>
                We do not knowingly solicit data from or market to children
                under 18 years of age. By using the Services, you represent that
                you are at least 18 or that you are the parent or guardian of
                such a minor and consent to such minor dependent’s use of the
                Services. If we learn that personal information from users less
                than 18 years of age has been collected, we will deactivate the
                account and take reasonable measures to promptly delete such
                data from our records. If you become aware of any data we may
                have collected from children under age 18, please contact us at
                __________.
              </p>
            </div>
            <div className="privact-rights">
              <h4>8. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
              <p>
                <b>In Short: </b> In some regions, such as the European Economic
                Area (EEA), United Kingdom (UK), and Switzerland, you have
                rights that allow you greater access to and control over your
                personal information. You may review, change, or terminate your
                account at any time.
              </p>
              <p>
                In some regions (like the EEA, UK, and Switzerland), you have
                certain rights under applicable data protection laws. These may
                include the right (i) to request access and obtain a copy of
                your personal information, (ii) to request rectification or
                erasure; (iii) to restrict the processing of your personal
                information; (iv) if applicable, to data portability; and (v)
                not to be subject to automated decision-making. In certain
                circumstances, you may also have the right to object to the
                processing of your personal information. You can make such a
                request by contacting us by using the contact details provided
                in the section{" "}
                <span>"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"</span> below.
              </p>
              <p>
                However, please note that this will not affect the lawfulness of
                the processing before its withdrawal nor, will it affect the
                processing of your personal information conducted in reliance on
                lawful processing grounds other than consent.
              </p>
              <div className="account-info">
                <h5>Account Information</h5>
                <p>
                  If you would at any time like to review or change the
                  information in your account or terminate your account, you
                  can:
                </p>
                <ul>
                  <li>
                    Log in to your account settings and update your user
                    account.
                  </li>
                </ul>
                <p>
                  Upon your request to terminate your account, we will
                  deactivate or delete your account and information from our
                  active databases. However, we may retain some information in
                  our files to prevent fraud, troubleshoot problems, assist with
                  any investigations, enforce our legal terms and/or comply with
                  applicable legal requirements.
                </p>
                <p>
                  If you have questions or comments about your privacy rights,
                  you may email us at Info@thegrowthcapital.nl.
                </p>
              </div>
            </div>
            <div className="do-not-track">
              <h4>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
              <p>
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ("DNT") feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. At this stage no uniform technology standard for
                recognizing and implementing DNT signals has been finalized. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy notice.
              </p>
            </div>
            <div className="update-notice">
              <h4>10. DO WE MAKE UPDATES TO THIS NOTICE?</h4>
              <p>
                <b>In Short:</b> Yes, we will update this notice as necessary to
                stay compliant with relevant laws.
              </p>
              <p>
                We may update this privacy notice from time to time. The updated
                version will be indicated by an updated "Revised" date and the
                updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy notice, we may
                notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy notice frequently to be informed of
                how we are protecting your information.
              </p>
            </div>
            <div className="contact-about-notice">
              <h4>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
              <p>
                If you have questions or comments about this notice, you may
                contact us by post at:
              </p>
              <p>The Growth Capital</p>
              <p>Weena 505 3013 AL Rotterdam</p>
              <p>Rotterdam</p>
              <p>Netherlands</p>
            </div>
            <div className="update-delete">
              <h4>
                12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </h4>
              <p>
                Based on the applicable laws of your country, you may have the
                right to request access to the personal information we collect
                from you, change that information, or delete it. To request to
                review, update, or delete your personal information,
              </p>
            </div>
          </div>
        </div>
        <div className="text-center">
          <RegisterationCode />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;

import CounterPageUP from '../CounterPageUP';
import "./NumberMain.css";
import people from "../../../assets/images/people.svg";
import traders from "../../../assets/images/tradesnumber.svg";
import { useTranslation } from 'react-i18next';
const NumberMain = () => {
  const { t } = useTranslation();
  return (
    <div className='numbermain d-flex justify-content-between flex-wrap'>
      <div className="numbercontent">
        <CounterPageUP
          counterImage={people}
          endingStage={3}
          countheading={t("numberTitle1")}
        />
      </div>
      <div className="numbercontent">
        <CounterPageUP
          counterImage={traders}
          endingStage={6}
          countheading={t("numberTitle2")}
        />
      </div>
      <div className="numbercontent">
        <CounterPageUP
          counterImage={people}
          endingStage={4}
          countheading={t("numberTitle3")} />
      </div>
    </div>
  )
}
export default NumberMain;
import React, { useState } from 'react';
import axios from 'axios';
import LabelComp from '../Subcomponents/LabelComp';
import CustomSnackbar from '../Subcomponents/SnackBar';
import "./ContactForm.css";
import { useTranslation } from 'react-i18next';
const ContactForms2 = () => {
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("");
    const [formData, setFormData] = useState({
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        msg: ""
    });
    const handleCheckBox = () => {
        setMessage("please accept the privacy policy");
        setSeverity("error");
        setBackgroundColor("error");
        setOpen(true);
    };
    const handleErrorEmail = () => {
        setMessage("Unable to send email!");
        setSeverity("error");
        setBackgroundColor("error");
        setOpen(true);
    };
    const handleSentEmail = () => {
        setMessage("Email sent successfully!");
        setSeverity("success");
        setBackgroundColor("#2c32b3");
        setOpen(true);
    };

    function handleChange(event) {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    function handleCheckboxChange() {
        setIsChecked(!isChecked);
    }
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const response = await axios.post('https://tgc-206443c30f66.herokuapp.com/email/send', formData);
        // const response = await axios.post('https://tgc-backend-bd13d5f3fc11.herokuapp.com/email/send', formData);
        if (isChecked) {
            if (response.status === 200) {
                handleSentEmail();
                setFormData({
                    email: "",
                    firstName: "",
                    lastName: "",
                    phone: "",
                    msg: ""
                });
                setIsChecked(false);
            } else {
                handleErrorEmail();
            }
        }
        else {
            handleCheckBox();
        }
    }
    return (
        <>
            <form onSubmit={handleFormSubmit}>
                <div className='contact-form'>
                    <div className='Contact-input-fields'>
                        <div className='contact-input'>
                            <LabelComp
                                labelContent={t("popoverFormFirstTitle")}
                                htmllabel="firstName" />
                            <input name="firstName"
                                type="text"
                                placeholder={t("popoverFormFirstPlaceholder")}
                                autoComplete="off"
                                value={formData.firstName}
                                onChange={handleChange}
                                required />
                        </div>
                        <div className='contact-input'>
                            <LabelComp
                                labelContent={t("popoverFormLastTitle")}
                                htmllabel="lastName"
                            />
                            <input name="lastName"
                                type="text"
                                autoComplete="off"
                                placeholder={t("popoverFormLastPlaceholder")}
                                value={formData.lastName}
                                onChange={handleChange}
                                required />
                        </div>
                        <div className='contact-input'>
                            <LabelComp
                                labelContent={t("popoverFormEmailText")}
                                htmllabel="email"
                            />
                            <input name="email"
                                type="email"
                                autoComplete="off"
                                placeholder={t("popoverFormEmailPlaceholder")}
                                value={formData.email}
                                onChange={handleChange}
                                required />
                        </div>
                        <div className='contact-input'>
                            <LabelComp
                                labelContent={t("popoverFormPhoneText")}
                                htmllabel="phone"
                            />
                            <input name="phone"
                                type="phone"
                                autoComplete="off"
                                placeholder={t("popoverFormPhonePlaceholder")}
                                value={formData.phone}
                                onChange={handleChange}
                                required />
                        </div>
                        <div className='contactform-textarea '>
                            <LabelComp
                                labelContent={t("popoverFormMessageText")}
                                htmllabel="msg"
                            />
                            <textarea name="msg"
                                rows="6"
                                required
                                value={formData.msg}
                                onChange={handleChange}></textarea>
                        </div>
                        <div className='contact-Check d-flex'>
                            <div >
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onClick={handleCheckboxChange}
                                />
                            </div>
                            <LabelComp
                                labelContent={t("popoverFormPolicy")}
                                htmllabel="checklabel" />
                        </div>
                        <div className="contact-btn">
                            <div>
                                <button type='submit'>{t("popoverFormButtonText")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <CustomSnackbar
                open={open}
                setOpen={setOpen}
                message={message}
                severity={severity}
                backgroundColor={backgroundColor} />
        </>
    )
}
export default ContactForms2;
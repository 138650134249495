import React from 'react';
import "./logo.css";
const Input = (props) => {
  return (
    <div className='input-main'>
        <input 
        placeholder={props.place} 
        type={props.typeitem} 
        required 
        autoComplete="off"
        />
    </div>
  )
}
export default Input;
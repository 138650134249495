import React,{useState,useEffect} from 'react';
import "./FundingCard.css";
import AnouncementTooltip from '../Subcomponents/AnouncementTooltip';
import jetimage from "../../assets/images/Plane-Icon.png";
import Para from '../Subcomponents/Para';
import { Link } from 'react-router-dom';
import Heading from '../Subcomponents/Heading';
import PdfViewer from '../Subcomponents/PdfViewer';
import RegisterationCode from '../RegisterationCode/RegisterationCode';
const FundingCard = () => {
    const [language, setLanguage] = useState('');
    useEffect(() => {
        const detectedLanguage = document.documentElement.lang;
        setLanguage(detectedLanguage);
    }, []);
    const pdfUrl = process.env.PUBLIC_URL + '/shariahcertificate.pdf';
    const pdfBusinessUrl = process.env.PUBLIC_URL + (language === "nl" ? '/factsheetdutch.pdf' : '/factsheeteng.pdf');
    const [showPDF, setShowPDF] = useState(false);
    const [showFactPDF, setShowFactPDF] = useState(false);
    const handleBtnClick = () => {
        setShowPDF(true);
        window.open(pdfUrl, "_blank");
    };
    const handleFactBtnClick = () => {
        setShowFactPDF(true);
        window.open(pdfBusinessUrl, "_blank");
    };
    return (
        <div className='container'>
            <div className='Funding-main'>
                <div className='Funding-text1 text-center'>
                    <h3>
                        Have a look at our funds !
                    </h3>
                </div>
                <div className='investing-button1 text-center mt-4'>
                    <Link onClick={handleFactBtnClick}>Business Fact Sheet</Link>
                    {showFactPDF && <PdfViewer pdfUrl={pdfBusinessUrl} />}
                </div>
                <div className='funding-stages py-5'>
                    <div className='funding-round text-center'>
                        <h6 id='specific-section'>
                            TGC Trading
                        </h6>
                        <div className='paraStyle'>
                            <p>
                                and
                            </p>
                            <p>
                                <a href="https://www.stichtingjuridischeigenaar.nl/">
                                    Stichting juridisch eigenaar TGC Trading
                                </a>
                            </p>
                        </div>
                        <div className='invest-real-content text-center mx-auto py-3'>
                            <Heading
                                heading="Liquidity at it’s best !" />
                            <Para
                                para="Welcome to TGC Trading, where we specialize in meticulously curated crypto assets verified by the 
                                Shariah Review Bureau. With a keen focus on ethical investment practices, we have transitioned from our
                                initial venture in the forex market, managing assets worth 1 million euros at our outset. Following
                                extensive consultation with Islamic scholars, we have made the decision to cease this activity, both
                                for ourselves and for potential investors." />
                            <Para
                                para="Leveraging our invaluable experience from past endeavors, we have harnessed our expertise to 
                                establish a robust crypto trading framework. Prior to opening any of our funds to public investment,
                                including this one, we rigorously test them with our own capital. Anticipating a monthly return ranging
                                from 1% to 5%, our objective is to create sustainable liquidity for our valued investors, adapting to
                                market conditions with astute precision." />
                            <Para
                                para="At TGC Trading, adherence to Sharia principles is paramount in our operations. We strictly avoid 
                                leveraging in our trading activities, ensuring that all currencies undergo thorough scrutiny before any
                                transactions are made. Each trade is executed under the meticulous supervision of our seasoned fund
                                manager, who brings years of trading experience to the table. Unlike many others in the industry, we
                                eschew dependence on algorithms or bots alone for trading, recognizing the inherent risks of relying
                                solely on automated systems in the unpredictable crypto market." />
                            <Para
                                para="Our primary aim is to deliver consistent returns, with a minimum target of approximately 20% 
                                annually which can grow over 100% with the right market movements. For your convenience, our fund is
                                easily accessible through our online platform, offering a seamless experience for trading, management,
                                and investment. As a reputable financial institution, TGC Trading B.V. is registered under the
                                supervision of the AFM, underscoring our unwavering commitment to transparency and accountability in
                                all our operations." />
                            <div className='investing-button'>
                                <div className='investing-button1'>
                                    <Link onClick={handleBtnClick}>Shariah Certificate</Link>
                                    {showPDF && <PdfViewer pdfUrl={pdfUrl} />}
                                </div>
                                <div className='investing-button1'>
                                    <Link to="/howitworks">Start now !</Link>
                                </div>
                            </div>
                            <div className='bottom-para'>
                                <Para para="Investing is never a guaranteed road to profit and always carries an element of risk, which 
                                could lead to losses. While previous returns, expected returns, and probability projections can be useful 
                                for informational purposes only, they cannot be an indicator of future performance. They are not 
                                indicative of future returns by that system and are not indicative of future returns which will be realized
                                by you."/>
                            </div>
                            <RegisterationCode />
                        </div>
                    </div>
                    <div id="specific-section2">
                        <div className='funding-round text-center'>
                            <p>
                                TGC European Residential Properties fund
                            </p>
                            <div className='funding-para-content'>
                                <Para para="Introducing TGC European Residential Real Estate Properties, an exclusive opportunity brought 
                                to you by The Growth Capital. As specialists in strategic real estate investment, we focus on acquiring
                                and holding premium residential properties in the rapidly expanding regions of the Netherlands." />
                                <Para para="With a meticulous eye for growth potential, we curate a specialized portfolio designed to 
                                maximize returns for our investors. Our investment strategy centers on long-term value appreciation, with
                                anticipated annual increases of approximately 5% in housing value. Additionally, our rental operations
                                are projected to yield an annual income of approximately 8%, offering a combined return on investment
                                exceeding 10%." />
                                <Para para="At TGC European Residential Real Estate Properties, we take pride in our comprehensive 
                                approach to investment management. From initial purchase and rigorous valuation to meticulous control
                                of rental operations and strategic end-sale planning, our team oversees every aspect of the investment
                                process." />
                                <Para para="This opportunity is tailored for investors seeking stability and steady returns through 
                                long-term investment in premium residential real estate. With TGC European Residential Real Estate
                                Properties, you can trust in our expertise and dedication to delivering exceptional results for your
                                investment portfolio. Join us in shaping the future of real estate investment in the thriving European
                                market with TGC." />
                            </div>
                        </div>
                    </div>
                    <div id="specific-section4">
                        <div className='funding-round text-center'>
                            <p>
                                TGC Development fund
                            </p>
                            <div className='funding-para-content'>
                                <Para para="Introducing the TGC Development Fund, where opportunity meets expertise in the dynamic 
                                realm of real estate investment. With a focus on the flourishing regions of the Netherlands to start, our 
                                fund targets specific housing shortages, offering lucrative returns projected
                                between 20% to 40% on investment." />
                                <Para para="At TGC Development, we orchestrate every stage of the investment process, from meticulous 
                                valuation to strategic purchasing, seamless construction, and proficient sales. Our team employs rigorous
                                analysis to select and engage in the most promising development projects, ensuring optimal returns for
                                our investors." />
                                <Para para="Drawing from our extensive experience and success in previous ventures, we bring a wealth 
                                of knowledge and expertise to the real estate market. Much like our approach to past endeavors, the
                                TGC Development Fund begins with rigorous testing using our own capital before opening opportunities
                                to the public." />
                                <Para para="In alignment with our commitment to transparency and ethical practices, our fund operates 
                                within strict compliance with regulatory standards. Our dedication to ethical investment is reflected
                                in our thorough adherence to Sharia principles, ensuring that our activities uphold the highest standards
                                 of integrity and responsibility." />
                                <Para para="With a vision for sustainable growth and a track record of success, the TGC Development 
                                Fund invites investors to join us in shaping the future of real estate investment in thriving European
                                markets. Discover the potential for unparalleled returns and strategic partnership with TGC Development
                                Fund today." />
                            </div>
                        </div>
                    </div>
                    <div id="specific-section1">
                        <div className='funding-round text-center'>
                            <p>
                                TGC European Commercial Properties fund
                            </p>
                            <div className='funding-image'>
                                <img src={jetimage} alt='jet-logo' />
                            </div>
                            <label>
                                <AnouncementTooltip
                                    anouncetime="Estimated launch 2024" />
                            </label>
                        </div>
                    </div>
                    <div id="specific-section3">
                        <div className='funding-round text-center'>
                            <p>
                                TGC European Retail Properties fund
                            </p>
                            <div className='funding-image'>
                                <img src={jetimage} alt='jet-logo' />
                            </div>
                            <label>
                                <AnouncementTooltip
                                    anouncetime="Estimated launch 2024" />
                            </label>
                        </div>
                    </div>
                    <div id="specific-section5">
                        <div className='funding-round text-center'>
                            <p>
                                TGC Venture Capital fund
                            </p>
                            <div className='funding-image'>
                                <img src={jetimage} alt='jet-logo' />
                            </div>
                            <label>
                                <AnouncementTooltip
                                    anouncetime="Estimated launch 2024" />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FundingCard;
import React from "react";
import "./LaunchCard.css";
import speaker from "../../../assets/images/speaker.png";

const LaunchCard = (props) => {
  return (
    <div className="launch-card-main d-flex flex-column justify-content-center align-items-center">
      <div className="launch-main-jet">
        <img src={props.launchMainJet} alt="jet-logo" />
      </div>
      <div className="launch-date">
        <span>
          <img src={speaker} alt="speak-logo" />
        </span>
        <h6>{props.launchDate}</h6>
      </div>
      <div className="launch-detail">
        <p>{props.launchDetail}</p>
      </div>
    </div>
  );
};

export default LaunchCard;

import React, { useEffect, useState } from "react";
import BlogContext from "./blogContext";
import supabase from "../../config/supabaseClient";

const BlogState = (props) => {
    // eslint-disable-next-line
    const [fetchError, setFetchError] = useState(null);
    const [state, setState] = useState([]);
    useEffect(() => {
        const fetchBlogs = async () => {
            const { data, error } = await supabase.from("blogsdata").select();
            if (error) {
                setFetchError("Could not fetch the blogs");
                setState([]);
            }
            if (data) {
                setState(data);
                setFetchError(null);
            }
        };
        fetchBlogs();
    }, []);
    return (
        <BlogContext.Provider value={state}>
            {props.children}
        </BlogContext.Provider>
    )
}
export default BlogState;
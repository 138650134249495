import React from 'react';
import "./OrPart.css";

const OrPart = () => {
  return (
    <div className='or-main text-center'>
        <span>
            OR
        </span>
    </div>
  )
}

export default OrPart;
import React from "react";
import ConditionComp from "../Subcomponents/ConditionComp/ConditionComp";
import Headdemo from "../Subcomponents/Headdemo";
import Footer from "../Subcomponents/HeaderComponents/Footer";
import Phone from "../Subcomponents/Phone";
import ProsperInvest from "../Subcomponents/ProsperInvest/ProsperInvest";
import DiscoverApp from "../Subcomponents/DiscoverApp/DiscoverApp";
import "./Frame2.css";
import Proposition from "../Subcomponents/Proposition/Proposition";
import estimateLogo from "../../assets/images/estimate-frame2.png";
import frame2Number from "../../assets/images/frame2-number.png";
import WhatWeDo from "../Subcomponents/WhatWeDo/WhatWeDo";
import ArrowBtn from "../Subcomponents/ArrowBtn/ArrowBtn";
import growthLogo from "../../assets/images/frame-data-img-black.png";
import bg1 from "../../assets/images/bg1-2.jpg";
import { useTranslation } from "react-i18next";

const Frame2 = () => {
  const { t } = useTranslation();
  const fundDetails = [
    { label: t("propositionFoundingYear"), value: 2023 },
    { label: t("propositionCapacity"), value: "€100M" },
    { label: t("propositionInvestment"), value: "€100.000,-" },
    { label: t("propositionManagement"), value: "1%" },
    { label: t("propositionPerformance"), value: "25% HWM" },
    { label: t("propositionPayout"), value: t("residentialPayoutDuration") },
  ];
  return (
    <div>
      <ConditionComp />
      <Headdemo />
      <div className="main-header-funds">
        <div className="main-header-funds-logo">
          <img src={bg1} alt="main-logo" />
          <div className="main-header-abs2">
            <img src={growthLogo} alt="logo" />
            <p>{t("residentialTitle")}</p>
          </div>
        </div>
      </div>
      <WhatWeDo />
      <div className="frame2-proposition">
        <Proposition
          estimateLogo={estimateLogo}
          propositionEstimate={t("propositionEstimatedTitle")}
          propositionLogoNumber={frame2Number}
          propositionProfileHead1={t("lowRiskTitle")}
          propositionProfilePara1={t("lowRiskPara")}
          propositionProfileHead2={t("highRiskTitle")}
          propositionProfilePara2={t("highRiskPara")}
          propositionContent2Head={t("propositionRiskTitle")}
          keyInfo={fundDetails}
          propositionSubHead={t("propositionKeyTitle")}
          propositionPara2={t("residentialPara1")}
          propositionPara1={t("residentialPara2")}
          propositionHead={t("propositionTitle")}
        />
      </div>
      <div className="frame1-arrow">
        <div className="frame1-arrow-content1">
          <ArrowBtn arrowAnchor="/howitworks" arrowText={t("startNowTitle")} />
        </div>
      </div>
      <DiscoverApp />
      <ProsperInvest />
      <Footer />
      <Phone />
    </div>
  );
};

export default Frame2;

import React,{useEffect,useState} from "react";
import PdfViewer from "../PdfViewer";

const GrowthPdf = () =>{
    const pdfFileName = process.env.PUBLIC_URL + '/thegrowthcapital.pdf';
    const [showPDF, setShowPDF] = useState(false);
    const handlePdfShow = () => {
        setShowPDF(true);
        window.open(pdfFileName, "_blank");
      };
      useEffect(() => {
        // Trigger the PDF viewer when the component mounts
        handlePdfShow();
        // eslint-disable-next-line
      }, []);

    return(
        <>
        {showPDF && <PdfViewer pdfUrl={pdfFileName} />}
        </>
    );
}
export default GrowthPdf;
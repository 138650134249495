import React from 'react';
import "./NewsStyles.css";

const NewsPageCompTitle = (props) => {
  return (
      <div className='news-title'>
          <h4>
              {props.newsTitle}
          </h4>
      </div> 
  )
}

export default NewsPageCompTitle;
import React from 'react'
import { Link } from 'react-router-dom';
import { ContentLogin } from '../Subcomponents/ContentLogin';
import Input from '../Subcomponents/Input';
import LogBtn from '../Subcomponents/Logbtn';
import Loginfoot from '../Subcomponents/Loginfoot';
import Logo from '../Subcomponents/Logo';
import "../Login/Login.css";
import Google from '../Subcomponents/Google';
const SignUp = () => {
  return (
    <div className='login-page-main container-fluid'>
        <div className='login-input-container'>
            <Logo/>
            <div className='login-page-content1'>
                <ContentLogin
                login_heading="Create an account to get started"
                login_para="Fill in your personal information, verify your identity and start investing"/>
                <Input
                place="Name"
                typeitem="text"
                />
                <Input
                place="Enter Your email"
                typeitem="email"
                />
                <Input
                place="Enter Your Password"
                typeitem="password"
                />
                <div className='input-check justify-content-between'>
                    <div className='d-flex'>
                        <Input
                        place=""
                        typeitem="checkbox"
                        />
                        <label>Remember for 30 days</label>
                    </div>
                    <div className='anchor-style'>
                        <Link to="#">Forget Password</Link>
                    </div>
                </div>
                <LogBtn
                btn_text="Create account"/>
                 <Google/>
                <div className='account-detail'>
                    <Loginfoot
                    sign="Don’t have an account?"
                    forget="Sign up"
                    signroute="#"/>
                </div>
            </div>
        </div>
        <div className='login-image signup-image-bg'>
            <Link to="#">Login   →</Link>
        </div>
    </div>
  )
}

export default SignUp;
import React from 'react';
import { Link } from 'react-router-dom';
import "./logo.css";
const Loginfoot = (props) => {
  return (
    <div className='login-foot'>
        <span>
            {props.sign}
        </span>
        <Link to={props.signroute}>
            {props.forget}
        </Link>
    </div>
  )
}
export default Loginfoot;
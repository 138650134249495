import React from 'react';
import { Line } from "react-chartjs-2";
// eslint-disable-next-line
import { Chart as ChartJS } from "chart.js/auto";


const InvestmentGraph=({chartData})=> {
      const options = {
        interaction: {
          mode: 'index',
          intersect: false,
        },
        elements: {
          line: {
            tension: 0.4,
          },
        },
      };
  return (
    <Line data={chartData} options={options}/>
  )
}

export default InvestmentGraph;
import React from "react";
import "./ConditionComp.css";
import conditionDutch from "../../../assets/images/Waarschuwing.png";
import conditionEnglish from "../../../assets/images/Waarschuwing ENG.png";
import { useTranslation } from "react-i18next";

const ConditionComp = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <div className="condition">
      <img
        src={language === "nl" ? conditionDutch : conditionEnglish}
        alt="condition"
      />
    </div>
  );
};

export default ConditionComp;

import React from "react";
import "./NewsStyles.css";

const NewsPageCompLogo = (props) => {
  return (
    <div className="news-logo">
      <img src={props.newsLogo} alt="news-logo" />
    </div>
  );
};

export default NewsPageCompLogo;

import React from 'react';
import Headdemo from '../Subcomponents/Headdemo';
import Footer from '../Subcomponents/HeaderComponents/Footer';
import Phone from '../Subcomponents/Phone';
import "./ComingSoon.css";
import ConditionComp from '../Subcomponents/ConditionComp/ConditionComp';
import { useTranslation } from 'react-i18next';

const ComingSoon = () => {
  const { t } = useTranslation();
  return (
    <>
      <ConditionComp />
      <Headdemo />
      <div className='comingsoon'>
        <h4>
          {t("comingTitle")}
        </h4>
      </div>
      <Footer />
      <Phone />
    </>
  )
}

export default ComingSoon;
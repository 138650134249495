import React from 'react'
import "./Headmain.css";
import { useTranslation } from 'react-i18next';
const HeaderMain = () => {
  const { t } = useTranslation();
  return (
    <div className='header-main'>
      <div className='video-background'>
        <div className='header-sec'>
          <div className='headInnovation' data-aos="fade-down" data-aos-duration="3000">
            <h1>
              {t("mainHeaderDataTitle")}
            </h1>
            <h5
              dangerouslySetInnerHTML={{
                __html: t('mainHeaderDataPara1'),
              }}>
            </h5>
            <h5
              dangerouslySetInnerHTML={{ __html: t('mainHeaderDataPara2') }}>
            </h5>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HeaderMain;

import React from 'react';
import { Link } from 'react-router-dom';
import "./ContactCard.css";
const ContactCard = (props)=> {
  return (
    <div className='contactcard-main'>
        <div className='contactcard-image mb-2'>
            <img src={props.contactImage} alt="contact-logo"/>
        </div>
        <h4>{props.contactCardHead}</h4>
        <p>{props.contactCardPara}</p>
        <Link to={props.cardLink}>{props.cardLinkContent}</Link>
    </div>
  )
}
export default ContactCard;
import MainRoute from "./routes/MainRoute";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"; 
const App = () => {
  return (
    <div className="App">
      {/* <div id="google_translate_element"> */}
      <MainRoute />
      {/* </div> */}
    </div>
  );
}
export default App;

import React from 'react';
import workpic from "../../assets/images/work-page.png";
import Para from '../Subcomponents/Para';
import "./WorkMain.css";
import ArrowText from '../Subcomponents/ArrowText';
const WorkMain=()=> {
  return (
    <div className='workmain-sec'>
      <div className='container'>
        <div className='d-flex justify-content-between flex-wrap'>
          <div className='workmain-container'>
            <div className='workmain-content'>
              <h4>
                Delivering strong returns for investors
              </h4>
              <Para
                para="The Growth Capital investment team carefully curates a dynamic and prime selection of top tier fund managers. Our
                proprietary framework expands our team experience with nearly 20+ years of combined know-how and expertise."/>
              <Para
                para="We only select the most fruitful funds that meet our investors needs and make sure that we are impartial and
                focused on lucrative outcomes."/>
              <Para
                para="One way we support our investors' growth is by offering access to a carefully selected group of top-tier fund
                managers. These managers have a proven track record of success in delivering strong returns and managing risk effectively."/>
              <Para
                para="By partnering with these fund managers, we can offer our investors a diverse range of investment opportunities 
                across different asset classes and geographies."/>
              <div className='workmain-image d-flex mt-3'>
                <ArrowText
                  linktext="Get started"
                  headanchor=""/>
              </div>
              </div>
            </div>
            <div className="workmain-content-image">
                <img src={workpic} alt="work"/>
            </div>
        </div>
      </div>
    </div>
  )
}
export default WorkMain;
import React from 'react';
import "./FundCard.css";

const FundCard=(props)=> {
  return (
    <div className='fundCard-main'>
        <h4>
            {props.fundHead}
        </h4>
        <p>
            {props.fundPara}
        </p>
    </div>
  )
}
export default FundCard;
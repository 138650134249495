import React from "react";
import "./NumberTextCard.css";

const NumberTextCard = (props) => {
  return (
    <div className="number-text-main">
      <h4>{props.numberTextNumber}</h4>
      <p>{props.numberTextDescription}</p>
    </div>
  );
};

export default NumberTextCard;

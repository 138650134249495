import React from 'react';
import "./TradingCard.css";
const TradingCard = (props) =>{
  return (
    <div className='TradingCard-sec'>
        <h4>
            {props.FundingTitle}
        </h4>
        <p>
          <span>
            {props.FundingStatus}
          </span>
        </p>
    </div>
  )
}
export default TradingCard;
import React from "react";
import "./Proposition.css";

const Proposition = (props) => {
  return (
    <div className="proposition-main">
      <div className="proposition-content1">
        <h4>{props.propositionHead}</h4>
        <p>{props.propositionPara1}</p>
        <p>{props.propositionPara2}</p>
        <h5>{props.propositionSubHead}</h5>
        <div className="proposition-detail">
          {props.keyInfo.map((info, index) => (
            <p key={index}>
              <span>{info.label}:</span> {info.value}
            </p>
          ))}
        </div>
      </div>
      <div className="proposition-content2">
        <h4 className="text-center">{props.propositionContent2Head}</h4>
        <div className="proposition-profile-logo">
          <div className="proposition-profile1">
            <h6>{props.propositionProfileHead1}</h6>
            <p>{props.propositionProfilePara1}</p>
          </div>
          <div className="proposition-profile2">
            <h6>{props.propositionProfileHead2}</h6>
            <p>{props.propositionProfilePara2}</p>
          </div>
        </div>
        <div className="proposition-profile-logoNumber">
          <img src={props.propositionLogoNumber} alt="pro-logo" />
        </div>
        <h5>{props.propositionEstimate}</h5>
        <div className="propositionEstimate-logo">
          <img src={props.estimateLogo} alt="estimate-logo" />
        </div>
      </div>
    </div>
  );
};

export default Proposition;

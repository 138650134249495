import React from 'react';
import "./Founder.css";
const Founder=(props)=> {
  return (
    <div className='founder-main'>
        <div className='founder-image'>
            <img src={props.founderImage} alt='founder-tag'/>
        </div>
        <div className='founder-image-content'>
            <h4>
                {props.founderhead}
            </h4>
            <span>
                {props.founderSpanText}
            </span>
            {/* <p>
                {props.founderpara}
            </p> */}
        </div>
    </div>
  )
}
export default Founder;
import React from 'react';
import "./TermUseCard.css";

const TermCardList = (props) => {
  return (
    <div className='termCardList'>
        <div className='termcardlist-head'>
            <h4>
                {props.termListHead}
            </h4>
        </div>
        <div className='termcardlist-list'>
            <ol>
                {props.listItem.map(item => {
            return(
                <li>
                    {item}
                </li>
            )})}
            </ol>
        </div>

    </div>
  )
}

export default TermCardList
import CampaignSharpIcon from '@mui/icons-material/CampaignSharp';
import "./AnouncementTooltip.css";
const AnouncementTooltip =  (props) =>{
  return (
    <>
    {/* <OverlayTrigger
        key={"top"}
        placement={"top"}
        overlay={
        <Tooltip id={`tooltip-${"top"}`} className='anouncetool-bg'>
            {props.tooltipContent}
        </Tooltip>
        }
    > */}
    <div className='d-flex'>
    <div className='anouncement-card'>
      <CampaignSharpIcon/>
    </div>
    <div className='anoouncement-time my-auto'>
      <p>
        {props.anouncetime}
      </p>

    </div>

    </div>
    
       
    {/* </OverlayTrigger> */}
    </>
  );
}

export default AnouncementTooltip;
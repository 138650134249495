import React from 'react';
import { Link } from 'react-router-dom';
import "./WorkAbout.css";
const WorkAbout=(props)=> {
  return (
    <div className='WorkAbout-main text-center'>
        <h4>{props.WorkAbouthead}</h4>
        <span><Link to={props.WorkAboutLink1}>{props.WorkAboutLinktitle1}</Link> / <Link to={props.WorkAboutLink2}>{props.WorkAboutLinktitle2}</Link> / <Link to={props.WorkAboutLink3}>{props.WorkAboutLinktitle3}</Link></span>
    </div>
  )
}
export default WorkAbout;
import React from 'react';
import { Pie } from 'react-chartjs-2';
// eslint-disable-next-line
import { Chart as ChartJS } from "chart.js/auto";

const InvestmentPie=({chartDataPie})=> {
  const Options = {
    plugins: {
      doughnutlabel: {
        labels: [
          {
            text: 'Doughnut Chart',
            font: {
              size: 20,
            },
            color: '#000000',
          },
        ],
      },
    },
    cutout: '75%',
  };
  return (
    <Pie data={chartDataPie} options={Options}/>
  )
}

export default InvestmentPie;
import React from 'react';
import "./Heading.css";

const Heading= (props) => {
  return (
    <div className='heading-main'>
        <h3>
            {props.heading}
        </h3>
    </div>
  )
}

export default Heading;
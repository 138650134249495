import React from "react";
import ConditionComp from "../Subcomponents/ConditionComp/ConditionComp";
import Headdemo from "../Subcomponents/Headdemo";
import Footer from "../Subcomponents/HeaderComponents/Footer";
import Phone from "../Subcomponents/Phone";
import ProsperInvest from "../Subcomponents/ProsperInvest/ProsperInvest";
import DiscoverApp from "../Subcomponents/DiscoverApp/DiscoverApp";
import "./Frame3.css";
import Proposition from "../Subcomponents/Proposition/Proposition";
// import estimateLogo from "../../assets/images/frame3-estimate.png";
import estimateLogo2 from "../../assets/images/estimate_development_page.svg";
import frame2Number from "../../assets/images/frame3-number.png";
import WhatWeDo from "../Subcomponents/WhatWeDo/WhatWeDo";
import ArrowBtn from "../Subcomponents/ArrowBtn/ArrowBtn";
import NumberTextCard from "../Subcomponents/NumberTextCard/NumberTextCard";
import Heading from "../Subcomponents/Heading";
import growthLogo from "../../assets/images/frame-data-img-black.png";
import bg1 from "../../assets/images/bg1-1.jpg";
import { useTranslation } from "react-i18next";

const Frame3 = () => {
  const { t } = useTranslation();
  const fundDetails = [
    { label: t("propositionFoundingYear"), value: 2023 },
    { label: t("propositionCapacity"), value: "€100M" },
    { label: t("propositionInvestment"), value: "€100.000,-" },
    { label: t("propositionManagement"), value: "1%" },
    { label: t("propositionPerformance"), value: "25% HWM" },
    { label: t("propositionPayout"), value: t("propositionPayoutDuration") },
  ];

  return (
    <div>
      <ConditionComp />
      <Headdemo />
      {/* <HeaderLogo HeaderLogo={headDataLogo} /> */}

      <div className="main-header-funds">
        <div className="main-header-funds-logo">
          <img src={bg1} alt="main-logo" />
          <div className="main-header-abs3">
            <img src={growthLogo} alt="logo" />
            <p>{t("developmentFundTitle")}</p>
          </div>
        </div>
      </div>
      <div className="frame3-about">
        <Heading heading={t("aboutUsCapital")} />
        <div className="frame3-about-data">
          <div className="frame3-about-content1">
            <NumberTextCard
              numberTextDescription={t("developmentFundCard1Para")}
              numberTextNumber="+20"
            />
          </div>
          <div className="frame3-about-content2">
            <NumberTextCard
              numberTextDescription={t("developmentFundCard2Para")}
              numberTextNumber="+5000m2"
            />
          </div>
          <div className="frame3-about-content3">
            <NumberTextCard
              numberTextDescription={t("developmentFundCard3Para")}
              numberTextNumber="+20M"
            />
          </div>
          <div className="frame3-about-content4">
            <NumberTextCard
              numberTextDescription={t("developmentFundCard4Para")}
              numberTextNumber="€50M"
            />
          </div>
        </div>
      </div>

      <WhatWeDo />
      <div className="frame2-proposition">
        <Proposition
          estimateLogo={estimateLogo2}
          propositionEstimate={t("propositionEstimatedTitle")}
          propositionLogoNumber={frame2Number}
          propositionProfileHead1={t("lowRiskTitle")}
          propositionProfilePara1={t("lowRiskPara")}
          propositionProfileHead2={t("highRiskTitle")}
          propositionProfilePara2={t("highRiskPara")}
          propositionContent2Head={t("propositionRiskTitle")}
          keyInfo={fundDetails}
          propositionSubHead={t("propositionKeyTitle")}
          propositionPara2={t("developmentFundPara2")}
          propositionPara1={t("developmentFundPara1")}
          // propositionPara2="Our Crypto Fund empowers investors to capitalize on the dynamic and lucrative world of cryptocurrencies, offering
          // a diversified portfolio managed by seasoned experts in the field."
          // propositionPara1="A premier investment opportunity tailored for discerning clients at the forefront of the digital financial revolution."
          propositionHead={t("propositionTitle")}
        />
      </div>
      <div className="frame1-arrow">
        <div className="frame1-arrow-content1">
          <ArrowBtn arrowAnchor="/howitworks" arrowText={t("startNowTitle")} />
        </div>
      </div>
      <DiscoverApp />
      <ProsperInvest />
      <Footer />
      <Phone />
    </div>
  );
};

export default Frame3;

import React from 'react';
import './logo.css';
export const ContentLogin = (props) => {
  return (
    <div className='login-content'>
        <h3>{props.login_heading}</h3>
        <h6>
          {props.LoginSubHead}
        </h6>
        <p>{props.login_para}</p>
    </div>
  )
}

import React from "react";
import "./FundCard.css";
import { Link } from "react-router-dom";

const FundCard = (props) => {
  return (
    <div>
      <Link to={props.fundCardRoute}>
        <div className="fund-card-main">
          <div className="fund-card-logo">
            <img src={props.fundCardLogo} alt="fund-logo" />
          </div>
          <div className="fund-card-text">
            <h4>{props.fundCardText}</h4>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default FundCard;

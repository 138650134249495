import React from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import "./PhotoSlider.css";
import { useLocation } from 'react-router-dom';

const PhotoSlider = ({ slides }) => {
    const location = useLocation();
    let options1;
    if (location.pathname === "/principles") {
        options1 = {
            responsiveClass: true,
            nav: false,
            dots: false,
            autoplay: true,
            loop: true,
            autoplayTimeout: 2000,
            autoplayHoverPause: true,
            smartSpeed: 3000,
            responsive: {
                320: {
                    items: 1,
                },
                500: {
                    items: 1,
                },
                600: {
                    items: 3,
                },
                768: {
                    items: 2.2,
                },
                992: {
                    items: 3,
                },
                1024: {
                    items: 3,
                    margin: 40,
                },
                1366: {
                    items: 4,
                    margin: 40,
                }
            },
        };
    } else {
        options1 = {
            responsiveClass: true,
            nav: false,
            dots: false,
            autoplay: true,
            loop: true,
            autoplayTimeout: 2000,
            autoplayHoverPause: true,
            smartSpeed: 3000,
            responsive: {
                320: {
                    items: 1,
                },
                500: {
                    items: 1,
                },
                600: {
                    items: 3,
                },
                768: {
                    items: 2.2,
                },
                992: {
                    items: 3.2,
                },
                1025: {
                    items: 3,
                }
            },
        };

    }
    return (
        <div className='dragonSlider-main dragon-mar'>
            <OwlCarousel className="owl-theme-review" {...options1}>
                {slides.map((slide, index) => (
                    <div className="item" key={index}>
                        <div className='image-item'>
                            <img src={require(`../../../assets/images/${slide.icon}`)} alt="icons" />
                        </div>
                        <div className='item-content text-center'>
                            <h3>{slide.title}</h3>
                            <p>{slide.description}</p>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
        </div>
    )
}

export default PhotoSlider;

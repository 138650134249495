import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import mainlogo from "../../assets/images/tgc-logo.svg";
import DropMenu from './DropMenu';
import invest1 from "../../assets/images/invest1.png";
import invest2 from "../../assets/images/invest2.png";
import invest3 from "../../assets/images/invest3.png";
import comp1 from "../../assets/images/comp1.png";
import comp5 from "../../assets/images/comp5.png";
import comp6 from "../../assets/images/comp6.png";
import tool1 from "../../assets/images/home-icon.svg";
import stock from "../../assets/images/stock-exchange.svg";
import crypto from "../../assets/images/crypto-icon.svg";
import lock from "../../assets/images/lock-icon.svg";
import news from "../../assets/images/newspaper-icon.png";
import LanguageIcon from '@mui/icons-material/Language';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { Link } from 'react-router-dom';
import england from "../../assets/images/england.png";
import netherland from "../../assets/images/netherland.png";
import palm from "../../assets/images/Praying-Icon.png";
import "./Headdemo.css";
import LetUSCallPopover from '../Popover/LetUSCallPopover';
import { useTranslation } from 'react-i18next';

const Headdemo = () => {
    const { t, i18n } = useTranslation();
    const languages = [
        { code: "en", codeName: "English" },
        { code: "nl", codeName: "Dutch" }
    ]
    const tgcPropertyUrl = i18n.language === "en" ? "https://www.tgcrealestate.nl/en" : "https://www.tgcrealestate.nl/nl";
    const handleClickUrl = () => {
        window.open(tgcPropertyUrl, "_blank");
    };
    return (
        <Navbar sticky="top" bg="light" expand="lg">
            <Container>
                <Navbar.Brand><Link to="/"><div className='headlogo-image header-relative'><img src={mainlogo} alt="logo-tag" /></div></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll" className='collapse-abs'>
                    <Nav className="me-auto my-2 my-lg-0 navbar-data">
                        <div className='Drop-down'>
                            <NavDropdown title={t("headNavigationInvest")} id="navbarScrollingDropdown">
                                <div className='drop-items drop-span'>
                                    <NavDropdown.Item>
                                        <Link to="/howitworks">
                                            <DropMenu
                                                pictureflag={invest1}
                                                dropitemHead={t("headerWorkTitle")}
                                                dropitemspan=""
                                                dropitempara={t("headerWorkText")} />
                                        </Link>
                                    </NavDropdown.Item>
                                </div>
                                <div className='drop-items drop-span'>
                                    <NavDropdown.Item>
                                        <Link to="/benefit">
                                            <DropMenu
                                                pictureflag={invest2}
                                                dropitemHead={t("headerFeatureTitle")}
                                                dropitemspan=""
                                                dropitempara={t("headerFeatureText")} />
                                        </Link>
                                    </NavDropdown.Item>
                                </div>
                                <div className='drop-items drop-span'>
                                    <NavDropdown.Item>
                                        <Link to="/funds">
                                            <DropMenu
                                                pictureflag={invest3}
                                                dropitemHead={t("headerDirectTitle")}
                                                dropitemspan=""
                                                dropitempara={t("headerDirectText")} />
                                        </Link>
                                    </NavDropdown.Item>
                                </div>
                                <div className='drop-items drop-span shariah-color-image'>
                                    <NavDropdown.Item>
                                        <Link to="/principles">
                                            <div className='d-flex drop-shraiah'>
                                                <DarkModeIcon className='shraiaf-icon' />
                                                <div className='sharaih-content'>
                                                    <h4>{t("headerCompanyTitle")}</h4>
                                                    <p>{t("headerCompanyText")}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </NavDropdown.Item>
                                </div>
                                <div className='drop-items drop-span'>
                                    <NavDropdown.Item>
                                        <LetUSCallPopover />
                                    </NavDropdown.Item>
                                </div>
                                {/* <div className='drop-items invest-bottom-button'>
                                    <div className='invest-bottom d-flex'>
                                        <PopoverMain />
                                    </div>
                                </div> */}
                            </NavDropdown>
                        </div>
                        <div className='Drop-down'>
                            <NavDropdown title={t("headNavigationCompany")} id="navbarScrollingDropdown">
                                <div className='drop-items drop-span'>
                                    <NavDropdown.Item>
                                        <Link to="/about">
                                            <DropMenu
                                                pictureflag={comp1}
                                                dropitemHead={t("headerAboutTitle")}
                                                dropitemspan=""
                                                dropitempara={t("headerAboutText")} />
                                        </Link>
                                    </NavDropdown.Item>
                                </div>
                                <div className='drop-items drop-span'>
                                    <NavDropdown.Item>
                                        <Link to="/afmregisteration">
                                            <DropMenu
                                                pictureflag={comp5}
                                                dropitemHead={t("headerAfmTitle")}
                                                dropitemspan=""
                                                dropitempara={t("headerAfmText")} />
                                        </Link>
                                    </NavDropdown.Item>
                                </div>
                                <div className='drop-items drop-span'>
                                    <NavDropdown.Item>
                                        <Link to="/contact">
                                            <DropMenu
                                                pictureflag={comp6}
                                                dropitemHead={t("headerContactTitle")}
                                                dropitemspan=""
                                                dropitempara={t("headerContactText")} />
                                        </Link>
                                    </NavDropdown.Item>
                                </div>
                                <div className='drop-items drop-span'>
                                    <NavDropdown.Item>
                                        <Link to="/latestnews">
                                            <DropMenu
                                                pictureflag={news}
                                                dropitemHead={t("headerLatestTitle")}
                                                dropitemspan=""
                                                dropitempara={t("headerLatestText")} />
                                        </Link>
                                    </NavDropdown.Item>
                                </div>
                                <div className='drop-items drop-span'>
                                    <NavDropdown.Item>
                                        <Link to="/foundation">
                                            <DropMenu
                                                pictureflag={palm}
                                                dropitemHead={t("headerFoundationTitle")}
                                                dropitemspan=""
                                                dropitempara={t("headerFoundationText")} />
                                        </Link>
                                    </NavDropdown.Item>
                                </div>
                            </NavDropdown>
                        </div>
                        <div className='Drop-down'>
                            <NavDropdown title={t("headNavigationTool")} id="navbarScrollingDropdown">
                                <div className='drop-items drop-span'>
                                    <NavDropdown.Item>
                                        <div onClick={handleClickUrl}>
                                            <DropMenu
                                                pictureflag={tool1}
                                                dropitemHead={t("headRealToolHeading")}
                                                dropitemspan=""
                                                dropitempara={t("headRealToolDescription")} />
                                        </div>
                                    </NavDropdown.Item>
                                </div>
                                <div className='drop-items drop-span drop-relative'>
                                    <NavDropdown.Item>
                                        <Link to="#">
                                            <DropMenu
                                                pictureflag={crypto}
                                                dropitemHead={t("headCryptoHeading")}
                                                dropitemspan=""
                                                dropitempara={t("headCryptoDescription")}
                                            />
                                        </Link>
                                        <div className="drop-abs-logo">
                                            <img src={lock} alt="lock=logo" />
                                        </div>
                                    </NavDropdown.Item>
                                </div>
                                <div className='drop-items drop-span drop-relative'>
                                    <NavDropdown.Item>
                                        <Link to="#">
                                            <DropMenu
                                                pictureflag={stock}
                                                dropitemHead={t("headStockHeading")}
                                                dropitemspan=""
                                                dropitempara={t("headStockDescription")}
                                            />
                                        </Link>
                                        <div className="drop-abs-logo">
                                            <img src={lock} alt="lock=logo" />
                                        </div>
                                    </NavDropdown.Item>
                                </div>
                            </NavDropdown>
                        </div>
                    </Nav>
                    <div className='main-header-lang'>
                        <div className='d-flex flag-main' onClick={() => i18n.changeLanguage(languages[0].code)}>
                            <div className='flag-image'>
                                <img src={england} alt="flag-tag" />
                            </div>
                            <h4>
                                {languages[0].codeName}
                            </h4>
                        </div>
                        <div className='d-flex flag-main flag-border' onClick={() => i18n.changeLanguage(languages[1].code)}>
                            <div className='flag-image'>
                                <img src={netherland} alt="flag-tag" />
                            </div>
                            <h4>
                                {languages[1].codeName}
                            </h4>
                        </div>

                    </div>
                    <div className='headdemo-lang d-none'>
                        <div className='Drop-down language-main'>
                            <div className='language-globe-icon'>
                                <LanguageIcon className='lang-color' />
                            </div>
                            <NavDropdown title="Global" id="navbarScrollingDropdown">
                                <div className='drop-items drop-span'>
                                    <NavDropdown.Item>
                                        <div className='d-flex flag-main'>
                                            <div className='flag-image'>
                                                <img src={england} alt="flag-tag" />

                                            </div>
                                            <h4>
                                                English
                                            </h4>
                                        </div>
                                    </NavDropdown.Item>
                                </div>
                                <div className='drop-items drop-span'>
                                    <NavDropdown.Item>
                                        <div className='d-flex flag-main'>
                                            <div className='flag-image'>
                                                <img src={netherland} alt="flag-tag" />
                                            </div>
                                            <h4>
                                                Dutch
                                            </h4>
                                        </div>
                                    </NavDropdown.Item>
                                </div>
                            </NavDropdown>
                        </div>
                    </div>
                    <div className='Headdemo-button d-none'>
                        <Link to="/login"><button>Log in</button></Link>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
export default Headdemo;
import React from "react";
import "./navbuttton.css";
import { Link } from "react-router-dom";
const LogBtn =(props)=>{
    return(
        <div className="Btn-main">
            <Link to ={props.navigation}>{props.btn_text}</Link>
        </div>
    );
}
export default LogBtn;
import React from "react";
import "./PrincipleHeadPara.css";
const PrincipleHeadPara = (props) => {
  return (
    <div className="principle-head-main">
      <p>
        <span>{props.principleHead}</span>
        {props.principlePara}
      </p>
    </div>
  );
};
export default PrincipleHeadPara;

import React from 'react';
import Havepic from "../../assets/images/Artboard 95logo-1.png";
import Question from './Question';
import "./HaveQuestion.css";
import { useTranslation } from 'react-i18next';
const HaveQuestion = () => {
  const { t } = useTranslation();
  return (
    <div className='Have-main-bg'>
        <div className='Have-main d-flex justify-content-between flex-wrap'>
            <div className='Have-content1'>
                <Question
                qHead={t("haveQuestionTitle")}
                qHead2={t("haveQuestionPara")}
                qPara={t("haveQuestionSubPara")}
                qcallTitle={t("callUsDirectlyTitle")}
                qcallNumber="(+31) 10 800 54 30"/>
            </div>
            <div className='Have-content2'>
                <img src={Havepic} alt="Question-tag"/>
            </div>
        </div>
    </div>
  )
}
export default HaveQuestion;
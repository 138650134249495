import React from "react";
import "./NewsStyles.css";

const NewsPageCompPara = (props) => {
  return (
    <div className="news-para">
      <p>{props.newsPara}</p>
    </div>
  );
};

export default NewsPageCompPara;

import React from 'react';
import "./LatestNewsCard.css";

const LatestNewsCard = (props) => {
  return (
    <div className="latest-news-card-main">
      <div className="latest-news-card-logo">
        <img src={props.latestCardLogo} alt="card-logo" />
      </div>
      <p>{props.latestCardDate}</p>
      <h4>{props.latestCardTitle}</h4>
    </div>
  );
}
export default LatestNewsCard;
import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useState,useEffect } from 'react';
import "./InvestSlider.css";
import InvestmentPie from "./InvestmentPie";
import InvestmentGraph from "./InvestmentGraph";

const InvestSlider = () => {
  const [age, setAge] = useState('100000');
  // eslint-disable-next-line
  const [initialValue, setInitailValue] = useState(0);
  const [valueBack, setValueBacked] = useState(0);
  const [realEstateValue, setRealEstateValue] = useState("0");
  const [tradingValue, setTradingValue] = useState("100");
  const [userDataPie, setUserDataPie] = useState({
    labels: ["Real Estate", "Trading"],
    datasets: [{
      data: [realEstateValue, tradingValue],
      backgroundColor: ["#5348CE", "#6d64d19c"]
    }]
  });
  const [userData, setUserData] = useState({
    labels: [],
    datasets: [{
      label: "Client Investment",
      data: [],
      backgroundColor: ["#6d64d19c"],
      fill: true
    }]
  });
  const valueText =(event,valuereturn)=>{
    setAge(valuereturn)
  }
  const valueinvestback=(event, valuereturn)=> {
    setValueBacked(valuereturn);
    setRealEstateValue(valuereturn);
    setTradingValue(100 - parseInt(valuereturn));
    setUserDataPie((prevState) => ({
      ...prevState,
      datasets: [
        {
          ...prevState.datasets[0],
          data: [valuereturn, 100 - parseInt(valuereturn)]
        }
      ]
    }));
  };
 
  const tradingFormula = () => {
    let tradingResult = ((13 / 100) * tradingValue);
    let realEstateResult = ((6 / 100) * realEstateValue);
    let yieldSum = (tradingResult + realEstateResult).toFixed(1);
    return yieldSum;
  };
  const findInvestment = () => {
    let myList = [];
    let pageData = tradingFormula();
    let investValue = age;
    for (let i = 0; i <= 9; i++) {
      let investIncrease = Math.ceil(parseFloat(investValue) + (parseFloat(investValue) * parseFloat(pageData)) / 100);
      myList.push({ year: i.toString()+" year", invest: investIncrease });
      investValue = investIncrease;
    }
    return myList;
  };


  useEffect(() => {
    let plotGraphList = findInvestment();
    setUserData({
      labels: plotGraphList.map((data) => data.year),
      datasets: [{
        label: "Client Investment",
        data: plotGraphList.map((data) => data.invest),
        backgroundColor: ["#6d64d19c"],
        fill: true
      }]
    });
    // eslint-disable-next-line
  }, [age,realEstateValue,tradingValue]);
  useEffect(() => {
  }, [realEstateValue,tradingValue]);
  return (
    <>
    <div>
    <div className='investSlider'>
      <p>
        £ {age}
      </p>
      <Box sx={{ width: 350 }}>
        <Slider
          defaultValue={100000}
          onChange={valueText}
          valueLabelDisplay="auto"
          step={10000}
          marks
          min={100000}
          max={500000}
        />
      </Box>
    </div>
    <div className='backedSlider'>
      <p>
        {valueBack} %
      </p>
      <Box sx={{ width: 350 }}>
        <Slider
          defaultValue={30}
          onChange={valueinvestback}
          valueLabelDisplay="auto"
          step={10}
          marks
          min={30}
          max={100}
        />
      </Box>
    </div>
    </div>
    <div className='graph-data-sets'>
       <div className='sliderdataPie'>
         <InvestmentPie  chartDataPie={userDataPie}/>
      </div> 
      <div className='sliderdatasine'>
         <InvestmentGraph chartData={userData} />
      </div>
    </div>
    </>
  );
}
export default InvestSlider;
import React from 'react';
import "./RegisterationCode.css";

const RegisterationCode = ()=> {
  return (
    <div className='trading-registeration'>
        <div className='trading-code'>
            <h5>
                Registration code of the company
            </h5>
            <h6>
                86489283
            </h6>
        </div>
        <div className='trading-address'>
            <p>
            Weena 505
            </p>
            <p>
            3013 AL Rotterdam
            </p>
        </div>
        <div className='trading-contact'>
            <p>
                <a href='mailto:Info@thegrowthcapital.nl'>Info@thegrowthcapital.nl</a>
            </p>
            <p>
                <a href='tel:+31 10 800 54 30'>+31 10 800 54 30</a>
            </p>
        </div>
    </div>
    
  )
}
export default RegisterationCode;
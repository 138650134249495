import React from 'react';
import "./TermUseCard.css";

const TermUseCard = (props) => {
  return (
    <div className='termcard-main'>
        <div className='termcard-head'>
            <h4>
                {props.termCardHead}
            </h4>
            <p>
                {props.termCardPara}
            </p>
        </div>
    </div>
  )
}

export default TermUseCard;
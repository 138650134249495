import React from 'react';
import "./InterstCard.css";
const InterestCard=(props)=> {
  return (
    <div className='interestcard-sec' data-aos="flip-left" data-aos-easing="ease-out-cubic"  data-aos-duration="3000">
        <div className='interestcard-img'>
            <img src={props.interestimg} alt="images"/>
        </div>
        <div className='interestcard-content flex-column align-self-end'>
        <h3>
            {props.interesthead}
        </h3>
        <p>
            {props.interestpara}
        </p>
        </div>
    </div>
  )
}
export default InterestCard;
import React from 'react';
import "./SignGoogle.css";

const SignGoogle= () => {
  return (
    <div className='google-main'>
        <span>Sign in with google</span>
    </div>
  )
}
export default SignGoogle;
import React from "react";
import newsLogo from "../../../assets/images/newsData7.jpeg";
import "./LatestNewsDetail.css";
import { useTranslation } from "react-i18next";
import NewsPageCompTitle from "../NewsPageComp/NewsPageCompTitle";
import NewsPageCompSubTitle from "../NewsPageComp/NewsPageCompSubTitle";
import NewsPageCompPara from "../NewsPageComp/NewsPageCompPara";
import ConditionComp from "../ConditionComp/ConditionComp";
import Footer from "../HeaderComponents/Footer";
import Headdemo from "../Headdemo";
import Phone from "../Phone";

const LatestNewsSeven = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ConditionComp />
      <Headdemo />
      <div className="news-content-width">
        <NewsPageCompTitle newsTitle={t("news7Title")} />
        <NewsPageCompSubTitle newsSubTitle={t("newsPage7Title1")} />
        <NewsPageCompPara newsPara={t("newsPage7Para71")} />
        <NewsPageCompPara newsPara={t("newsPage7Para72")} />
        <NewsPageCompSubTitle newsSubTitle={t("newsPage7Title2")} />
        <NewsPageCompPara newsPara={t("newsPage7Para73")} />
        <div className="news-main-logo">
          <img src={newsLogo} alt="news-logo" />
        </div>
      </div>
      <Footer />
      <Phone />
    </div>
  );
};

export default LatestNewsSeven;

import React from "react";
import "./NewsStyles.css";
const NewsPageCompSubTitle = (props) => {
  return (
    <div className="news-sub-title">
      <h5>{props.newsSubTitle}</h5>
    </div>
  );
};

export default NewsPageCompSubTitle;

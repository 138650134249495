import Accordion from 'react-bootstrap/Accordion';
import React from 'react';
import "./Accordionpage.css";
import { useTranslation } from 'react-i18next';
const AccordionPage = () => {
    const { t } = useTranslation();
    return (
        <div className='accord-main'>
            <Accordion defaultActiveKey={['0']} alwaysOpen>
                <div className='accord-item mb-4'>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <h4>
                                {t("workStartAccordionTitle1")}
                            </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>
                                {t("workStartAccordionPara1")}
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                </div>
                <div className='accord-item mb-4'>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <h4>
                                {t("workStartAccordionTitle2")}
                            </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>
                                {t("workStartAccordionPara2")}
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                </div>
                <div className='accord-item mb-4'>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <h4>
                                {t("workStartAccordionTitle3")}
                            </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>
                                {t("workStartAccordionPara3")}
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                </div>
                <div className='accord-item mb-4'>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                            <h4>
                                {t("workStartAccordionTitle4")}
                            </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>
                                {t("workStartAccordionPara4")}
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                </div>
            </Accordion>
        </div>
    );
}
export default AccordionPage;
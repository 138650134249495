import React from "react";
import growthAppLogo from "../../../assets/images/growthApp-logo.png";
import googleLogo from "../../../assets/images/googleLogo.png";
import appLogo from "../../../assets/images/appLogo.png";
import { Link } from "react-router-dom";
import "./DiscoverApp.css";
import { useTranslation } from "react-i18next";
const DiscoverApp = () => {
  const { t } = useTranslation();
  return (
    <div className="discover-main">
      <div className="discover-content1 my-auto">
        <div className="discover-content-inner">
          <h4>{t("directDiscoverTitle")}</h4>
          <div className="discover-app-anchors">
            <span>
              <Link to="#">
                <img src={appLogo} alt="store-logo" />
              </Link>
            </span>
            <span>
              <Link to="#">
                <img src={googleLogo} alt="store-logo" />
              </Link>
            </span>
          </div>
        </div>
      </div>
      <div className="discover-content2">
        <img src={growthAppLogo} alt="app-logo" />
      </div>
    </div>
  );
};

export default DiscoverApp;

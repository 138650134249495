import React from 'react';
import "./ContactCard.css";
const ContactCardNav = (props)=> {
  return (
    <div className='contactcard-main'>
        <div className='contactcard-image mb-2'>
            <img src={props.contactImage} alt="contact-logo"/>
        </div>
        <h4>{props.contactCardHead}</h4>
        <p>{props.contactCardPara}</p>
        <a href={props.cardLink}>{props.cardLinkContent}</a>
    </div>
  )
}
export default ContactCardNav;
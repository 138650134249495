import React from "react";
import "./LatestNewsDetail.css";
import Headdemo from "../Headdemo";
import ConditionComp from "../ConditionComp/ConditionComp";
import Footer from "../HeaderComponents/Footer";
import Phone from "../Phone";
import NewsPageCompLogo from "../NewsPageComp/NewsPageCompLogo";
import NewsPageCompTitle from "../NewsPageComp/NewsPageCompTitle";
import NewsPageCompBtn from "../NewsPageComp/NewsPageCompBtn";
import NewsPageCompSubTitle from "../NewsPageComp/NewsPageCompSubTitle";
import NewsPageCompPara from "../NewsPageComp/NewsPageCompPara";
import n2 from "../../../assets/images/n2.png";
import NewsCompGrowthLogo from "../NewsPageComp/NewsCompGrowthLogo";
import growthLogo from "../../../assets/images/Artboard 95logo-3.png";
import { useTranslation } from "react-i18next";

const LatestNewsTwo = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ConditionComp />
      <Headdemo />
      <div className="news-content-width">
        <NewsCompGrowthLogo newsGrowthLogo={growthLogo} />
        <NewsPageCompLogo newsLogo={n2} />
        <NewsPageCompTitle newsTitle={t("news2Title")} />
        <div className="latest-news-btns">
          <div className="latestnew-btn1 ">
            <NewsPageCompBtn
              newsAnchorTitle={t("newsPageButton2")}
              newsAnchor="#"
            />
          </div>
          <div className="latestnew-btn2">
            <NewsPageCompBtn
              newsAnchorTitle={t("fundTitle")}
              newsAnchor="/funds"
            />
          </div>
        </div>
        <NewsPageCompSubTitle newsSubTitle={t("newsPage2Title1")} />
        <NewsPageCompPara newsPara={t("newsPage2Para1")} />
        <NewsPageCompPara newsPara={t("newsPage2Para2")} />
        <NewsPageCompSubTitle newsSubTitle={t("newsPage2Title2")} />
        <NewsPageCompPara newsPara={t("newsPage2Para3")} />
      </div>
      <Footer />
      <Phone />
    </div>
  );
};

export default LatestNewsTwo;

import React from "react";
import { Link } from "react-router-dom";
import "./MobilePage.css";
import Mockup from "../../assets/images/Design Mockup-01.png"
import TradingCard from "../Subcomponents/TradingCard";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";
import GetAppPopover from "../Popover/GetAppPopover";
import { useTranslation } from "react-i18next";
const MobilePage = () => {
    const { t } = useTranslation();
    return (
        <div className="mobile-main ">
            <div className="mobile-head-content text-center">
                <h3>
                    {t("joinTitle")}
                </h3>
                <p>
                    {t("serviceTitle")}
                </p>
                <div className="mobile-tabs">
                    <div className="mobile-anc1">
                        <GetAppPopover />
                    </div>
                    <div className="mobile-anc2">
                        <Link to="/howitworks">{t("learnTitle")}</Link>
                    </div>
                </div>
                <div className="funding-rounds">

                </div>
                <div className="mobile-content2 flex-wrap">
                    <div className="mobile-content-data1">
                        <h3>
                            {t("fundTitle")}
                        </h3>
                        <div className="">
                            <div className="trade-sec">
                                <HashLink to="/cryptoFund" smooth>
                                    <TradingCard
                                        FundingTitle={t("cryptoPassiveTitle")}
                                        FundingStatus="Live" />
                                </HashLink>
                            </div>
                            <div className="trade-sec">
                                <HashLink to="/residential" smooth>
                                    <TradingCard
                                        FundingTitle={t("tgcResidentialTitle")}
                                        FundingStatus="Live" />
                                </HashLink>
                            </div>
                            <div className="trade-sec">
                                <HashLink to="/development" smooth>
                                    <TradingCard
                                        FundingTitle={t("tgcDevelopmentTitle")}
                                        FundingStatus="Live" />
                                </HashLink>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="mobile-tab-image">
                            <img src={Mockup} alt="mobile-pict" />
                        </div>
                        <p>
                            {t("appUpdatingTitle")}
                        </p>
                    </div>
                    <div className="mobile-content-data2">
                        <HashLink to="/funds" smooth>
                            <TradingCard
                                FundingTitle={t("tgcCommercialTitle")}
                                FundingStatus="Coming Soon" />
                        </HashLink>
                        <HashLink to="/funds" smooth>
                            <TradingCard
                                FundingTitle={t("tgcStockTitle")}
                                FundingStatus="Coming Soon" />
                        </HashLink>
                        <HashLink to="/funds" smooth>
                            <TradingCard
                                FundingTitle={t("tgcVentureTitle")}
                                FundingStatus="Coming Soon" />
                        </HashLink>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MobilePage;

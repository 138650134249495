import React from 'react'
import DropMenu from '../Subcomponents/DropMenu';
import invt4 from "../../assets/images/invest4.png";
import PopoverModal from './PopoverModal';
import Button from 'react-bootstrap/Button';
import "./LetUSCallPopover.css";
import { useTranslation } from 'react-i18next';


const LetUSCallPopover = () => {
  const { t } = useTranslation();
    const [modalShow, setModalShow] = React.useState(false);
  return (
    <div className='letcall-main'>
        <div className='letcall-btn'>
            <Button onClick={() => setModalShow(true)}>
                <DropMenu
                onClick={() => setModalShow(true)}
                pictureflag={invt4}
                dropitemHead={t("headerLetTitle")}
                dropitemspan=""
                dropitempara={t("headerLetText")}/>
            </Button>
        </div>
        
         <PopoverModal
         show={modalShow}
         onHide={() => setModalShow(false)}/>
    </div>
  )
}

export default LetUSCallPopover;
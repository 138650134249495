import React from 'react';
import { Link } from 'react-router-dom';
import "./ArrowBtn.css";
import purpleForward from "../../../assets/images/forward-purple.png";

const ArrowBtn = (props) => {
  return (
    <div className='arrow-btn-main'>
      <Link className="d-flex" to={props.arrowAnchor}>
        {props.arrowText}
        <span><img src={purpleForward} alt="arrow-logo"/></span>
      </Link>
    </div>
  );
}

export default ArrowBtn
import React from 'react';
import Headdemo from '../Subcomponents/Headdemo';
import Footer from '../Subcomponents/HeaderComponents/Footer';
import HaveQuestion from '../Subcomponents/HaveQuestion';
import Para from '../Subcomponents/Para';
// import afmImage from "../../assets/images/quote5.jpg";
import afmLogo from "../../assets/images/afm-logo.png";
import afmContact from "../../assets/images/afm-contact.png";
import "./AfmMain.css";
import Heading from '../Subcomponents/Heading';
import FundCard from '../Subcomponents/FundCard';
import Phone from '../Subcomponents/Phone';
import ConditionComp from '../Subcomponents/ConditionComp/ConditionComp';
import { useTranslation } from 'react-i18next';
// import QuotesPage from '../Subcomponents/QuotesPage/QuotesPage';
const AfmMain = () => {
  const { t } = useTranslation();
  return (
    <>
      <ConditionComp />
      <div className='afm-main'>
        <Headdemo />
        <div className='container'>
          <div className='afm-reg d-flex justify-content-between flex-wrap'>
            <div className='afm-reg-head' data-aos="fade-up" data-aos-duration="3000">
              <h3>
                {t("afmTitle")}
              </h3>
            </div>
            <div className='afm-reg-para' data-aos="fade-up" data-aos-duration="3000">
              <Para
                para={t("afmPara")} />
            </div>
          </div>
        </div>
        {/* <div className='afm-quote'>
          <QuotesPage
            quoteTitle="‘’Whoever strives for independence Allah will make him independent.’’"
            quoteReference="Prophet Muhammad ,sallaahu’alaihi wa sallam" />
        </div> */}
        {/* <div className='afm-image'>
          <img src={afmImage} alt="reg-afm" />
        </div> */}
        <div className='container'>
          <div className='afm-content-main d-flex justify-content-between flex-wrap'>
            <div className='afm-content-image' data-aos="flip-right" data-aos-duration="3000">
              <img src={afmContact} alt='afm-contact' />
            </div>
            <div className='afm-content-sec' data-aos="fade-left" data-aos-duration="3000">
              <div className='afm-content-logo'>
                <img src={afmLogo} alt='afm-logo' />
              </div>
              <Para
                para={t("afmSubPara")} />
              <Heading
                heading={t("afmComplianceTitle")} />
              <Para
                para={t("afmCompliancePara")} />
              <Heading
                heading={t("afmManagementTitle")} />
              <Para
                para={t("afmManagementPara")} />
              <Heading
                heading={t("afmSustainabilityTitle")} />
              <Para
                para={t("afmSustainabilityPara")} />
              <Heading
                heading={t("afmSustainabilityRiskTitle")} />
              <Para
                para={t("afmSustainabilityRiskPara")} />
            </div>
          </div>
          <div className='afm-registeration'>
            <div className='afm-registeration-head'>
              <h3>
                {t("afmRegistration")}
              </h3>
            </div>
            <div className="registeration-sec d-flex justify-content-between flex-wrap">
              <div className='registeration-card' data-aos="flip-right" data-aos-duration="3000">
                <FundCard
                  fundHead={t("afmRegisterTradingTitle")}
                  fundPara={t("afmRegistrationTradingPara")} />
              </div>
              <div className='registeration-card' data-aos="flip-right" data-aos-duration="3000">
                <FundCard
                  fundHead={t("afmRegistrationFundTitle")}
                  fundPara={t("afmRegistrationFundPara")} />
              </div>
            </div>
          </div>
          <HaveQuestion />
        </div>
        <Footer />
        <Phone />
      </div>
    </>
  )
}
export default AfmMain;
import React from 'react';
import Headdemo from '../Subcomponents/Headdemo';
import Footer from '../Subcomponents/HeaderComponents/Footer';
import TermUseCard from './TermUseCard';
import TermCardList from './TermCardList';
import RegisterationCode from '../RegisterationCode/RegisterationCode';
import ConditionComp from '../Subcomponents/ConditionComp/ConditionComp';

const TermUse = () => {
    const listc = ["Reporting: Investors are responsible for reporting any changes in their investment circumstances and personal details to the Fund promptly. This includes notifying the Fund of any significant changes in financial status, contact information, or residency.",
        "Due Diligence: Investors are encouraged to conduct their due diligence on the Fund, the cryptocurrency market, and any other relevant factors before making an investment. The Fund may provide periodic reports and updates to assist investors in their due diligence.",
        "Compliance: Investors must adhere to all relevant laws and regulations governing cryptocurrency investments and financial transactions. The Fund may also provide guidance to assist investors in understanding their compliance obligations."]
    const listb = ["Application: To initiate an investment, an Investor is required to complete the Fund's comprehensive application process, which includes the submission of all pertinent documents and information.",
        "Due Diligence: The Fund undertakes a meticulous due diligence procedure to verify an Investor's eligibility and suitability for investment, including reviewing the investor's financial situation and investment objectives.",
        "Acceptance: Following due diligence, the Fund holds the right to either accept or reject any investment application at its discretion.",
        "Investment Terms: Once accepted, the Investor will receive a detailed breakdown of the Fund's investment terms. These terms encompass the operation of the fund, associated fees, and the potential risks associated with investing in cryptocurrencies. This document may also include a summary of the fund's investment strategy and a disclosure of any conflicts of interest."]
    const lista = ['AFM: The "AFM" refers to the Netherlands Authority for the Financial Markets, the regulatory body responsible for overseeing financial markets in the Netherlands.',
        'Investor: An "Investor" is any individual or entity that invests in the Fund.',
        'Cryptocurrencies: "Cryptocurrencies" include digital or virtual currencies, such as Bitcoin, Ethereum, and other blockchain-based assets.',
        'Minimum Investment: The "Minimum Investment" indicates the minimum amount required for an individual to participate in the Fund, which is set at 100,000 euros.']
    return (
        <>
            <ConditionComp />
            <Headdemo />
            <div className="termuse-main">
                <div className='container'>
                    <div className="termusedata">
                        <h3>
                            Term of Use
                        </h3>
                        <p>
                            TGC Trading
                        </p>

                        <p>
                            Effective Date: 8 November 2023
                        </p>
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Introduction"
                            termCardPara='These Terms of Use (the "Terms") serve as the foundational legal framework governing the relationship between investors and [Your Investment Fund Name] (the "Fund"). The Fund offers investment opportunities in the world of cryptocurrencies, and by investing, you acknowledge and agree to adhere to these terms.' />
                    </div>
                    <div className='termuse-sec-list'>
                        <TermCardList
                            termListHead="Definitions"
                            listItem={lista} />
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Investment Eligibility"
                            termCardPara="To invest in the Fund, potential investors must meet the Minimum Investment requirement as well as any additional criteria outlined by the AFM or described in the Fund's prospectus." />
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Supervision and Regulation"
                            termCardPara="The Fund operates under the diligent supervision of the AFM, ensuring strict compliance with all applicable Dutch laws and regulations that govern cryptocurrency investment funds. The Fund also adheres to international standards for Anti-Money Laundering (AML) and Know Your Customer (KYC) requirements." />
                    </div>
                    <div className='termuse-sec-list'>
                        <TermCardList
                            termListHead="Investment Process"
                            listItem={listb} />
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Investment Risks"
                            termCardPara="Investing in cryptocurrencies involves inherent risks, including but not limited to market volatility, regulatory changes, technological vulnerabilities, liquidity risk, and potential loss of capital. By investing in the Fund, you confirm that you have recognized and comprehended these risks." />
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Fees and Expenses"
                            termCardPara="Investors are responsible for all fees and expenses associated with their investment, including but not limited to management fees, performance fees, and any other costs that are explicitly outlined in the Fund's prospectus. The Fund will provide a clear breakdown of all applicable fees and expenses, ensuring transparency for the Investor." />
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Withdrawal and Redemption"
                            termCardPara="Investors have the right to request withdrawals or redemptions subject to the conditions specified in the Fund's prospectus. The terms, timing, and limitations on such actions will be detailed in the prospectus. The Fund is committed to ensuring that redemptions are processed in a timely and equitable manner." />
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Confidentiality"
                            termCardPara="The Fund commits to maintaining the confidentiality of all Investor information in full compliance with relevant data protection and privacy laws. The Fund will not disclose investor details to any third party without the investor's explicit consent or as required by law. Investor information is securely stored and used exclusively for the purpose of managing the investment relationship." />
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Termination"
                            termCardPara="The Fund reserves the right to terminate the relationship with any Investor who fails to comply with these Terms or any applicable regulations. This could be due to violations of the Terms, non-payment of fees, or other significant breaches of the agreement." />
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Amendment of Terms"
                            termCardPara="The Fund retains the right to amend these Terms at any time. Investors will be informed of any changes or updates, and it is their responsibility to stay informed about the current Terms. The Fund will make reasonable efforts to provide prior notice of any significant amendments, especially those that may affect an Investor's rights and obligations.
                "/>
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Governing Law"
                            termCardPara="These Terms are governed by the laws of the Netherlands. In the event of any disputes or disagreements, the exclusive jurisdiction for resolving such matters will lie with the Dutch courts." />
                    </div>
                    <div className='termuse-sec-list'>
                        <TermCardList
                            termListHead="Investor Rights and Responsibilities"
                            listItem={listc} />
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Communication"
                            termCardPara="The Fund will maintain open lines of communication with investors and provide regular updates on fund performance, changes in investment strategy, and other relevant matters. Investors may contact the Fund's designated communication channel for inquiries, support, and to request information on their investment.
                "/>
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Risk Acknowledgment"
                            termCardPara="Investors acknowledge that cryptocurrency investments are highly speculative and involve significant risks. The Fund and the AFM advise investors to seek independent financial advice before making an investment. The Fund will provide educational resources and risk disclosures to ensure that investors are fully informed of the risks involved." />
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Indemnification"
                            termCardPara="Investors agree to indemnify and hold the Fund and its officers, directors, and employees harmless from any losses, liabilities, or damages resulting from their actions or inactions that breach these Terms or any applicable laws and regulations. This includes any liability arising from a breach of contract, negligence, or violation of laws and regulations." />
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Force Majeure"
                            termCardPara="The Fund shall not be held liable for any failure to perform its obligations under these Terms in the event of force majeure, including but not limited to acts of nature, war, or government actions beyond its control. The Fund will make reasonable efforts to mitigate the impact of force majeure events on the Investor's interests." />
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Entire Agreement"
                            termCardPara="These Terms, along with the Fund's prospectus and any supplemental agreements, constitute the entire agreement between the Investor and the Fund, supersedes all prior agreements, and may only be amended in writing by mutual consent. The Fund will make efforts to provide clear and concise documentation that outlines the complete terms of the investment." />
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Severability"
                            termCardPara="If any provision of these Terms is found to be unenforceable or invalid, that provision will be severed, and the remaining provisions will continue in full force and effect. The Fund will make efforts to draft the Terms in a manner that upholds the enforceability and validity of each provision." />
                    </div>
                    <div className='termuse-sec'>
                        <TermUseCard
                            termCardHead="Language"
                            termCardPara="These Terms are prepared in the English language, and any discrepancies between translations will be resolved in favor of the English version. The Fund will make reasonable efforts to provide translations and interpretation services for investors who require them." />
                    </div>
                </div>
            </div>
            <div className='text-center'>
                <RegisterationCode />
            </div>
            <Footer />
        </>
    )
}

export default TermUse;
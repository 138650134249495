import React from 'react'
import { Link } from 'react-router-dom';
import { ContentLogin } from '../Subcomponents/ContentLogin';
import Input from '../Subcomponents/Input';
import LogBtn from '../Subcomponents/Logbtn';
import Loginfoot from '../Subcomponents/Loginfoot';
import Logo from '../Subcomponents/Logo';
import OrPart from '../Subcomponents/OrPart';
import SignGoogle from '../Subcomponents/SignGoogle';
import "./Login.css";
const Login = () => {
  return (
    <div className='login-page-main container-fluid'>
        <div className='login-input-container'>
            <Logo/>
            <div className='login-page-content1'>
                <ContentLogin
                login_heading="Welcome back, Ilias"
                login_para="Please enter your details or sign-in with Google"/>
                <SignGoogle/>
                <OrPart/>
                <Input
                place="Enter Your email"
                typeitem="text"
                />
                <Input
                place="Enter Your Password"
                typeitem="password"
                />
                
                <div className='input-check justify-content-between'>
                    <div className='d-flex'>
                        <Input
                        place=""
                        typeitem="checkbox"
                        />
                        <label>Remember for 30 days</label>
                    </div>
                    <div className='anchor-style'>
                        <Link to="#">Forget Password</Link>
                    </div>
                </div>
                <LogBtn
                btn_text="Login"/>
                <div className='account-detail'>
                    <Loginfoot
                    sign="Don’t have an account?"
                    forget="Sign up"
                    signroute="#"/>
                </div>
            </div>
        </div>
        <div className='login-image login-image-bg'>
            {/* <Link to="#">Login   →</Link> */}
        </div>
    </div>
  )
}

export default Login;
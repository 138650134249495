import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useState, useEffect } from 'react';
import "./InvestSlider.css";
import "./TradingGraph.css";
import InvestmentPie from "./InvestmentPie";
import InvestmentGraph from "./InvestmentGraph";
import arrowLogo from "../../assets/images/two-side-arrow.png";
import { useTranslation } from 'react-i18next';
const TradingGraphs = () => {
  const { t } = useTranslation();
  const [age, setAge] = useState('100000');
  // eslint-disable-next-line
  const [initialValue, setInitailValue] = useState(0);
  const [valueBack, setValueBacked] = useState(0);
  // const [realEstateValue, setRealEstateValue] = useState("0");
  // const [tradingValue, setTradingValue] = useState("100");
  const [realEstateValue, setRealEstateValue] = useState("100");
  const [tradingValue, setTradingValue] = useState("0");
  const [userDataPie, setUserDataPie] = useState({
    labels: [t('tgcRealLabel'), t('tgcCryptoLabel')],
    datasets: [{
      data: [realEstateValue, tradingValue],
      backgroundColor: ["#5348CE", "#6d64d19c"]
    }]
  });
  const [userData, setUserData] = useState({
    labels: [],
    datasets: [{
      label: "Client Investment",
      data: [],
    }]
  });
  const valueText = (event, valuereturn) => {
    setAge(valuereturn)
  }
  const valueinvestback = (event, valuereturn) => {
    setValueBacked(valuereturn);
    setRealEstateValue(valuereturn);
    setTradingValue(100 - parseInt(valuereturn));
    setUserDataPie((prevState) => ({
      ...prevState,
      datasets: [
        {
          ...prevState.datasets[0],
          // data: [valuereturn, 100 - parseInt(valuereturn)]
          data: [100 - parseInt(valuereturn), valuereturn]
        }
      ]
    }));
  };

  const tradingFormula = () => {
    let tradingResult = ((13 / 100) * tradingValue);
    let realEstateResult = ((6 / 100) * realEstateValue);
    let yieldSum = (tradingResult + realEstateResult).toFixed(1);
    return yieldSum;
  };
  const tradeFormula = () => {
    let tradeResult = ((13 / 100) * tradingValue).toFixed(1);
    return tradeResult;
  }

  const realFormula = () => {
    let realResult = ((6 / 100) * realEstateValue).toFixed(1);
    return realResult;
  }
  const findInvestment = () => {
    let myList = [];
    let tradingList = [];
    let realEstateList = [];
    let pageData = tradingFormula();
    let investValue = age;
    for (let i = 0; i <= 9; i++) {
      let investIncrease = Math.ceil(parseFloat(investValue) + (parseFloat(investValue) * parseFloat(pageData)) / 100);
      myList.push({ year: i.toString() + t("graphYearTitle"), invest: investIncrease });
      investValue = investIncrease;
    }
    let tradingFormulaData = tradeFormula();
    let realFormulaData = realFormula();
    let tradeValue = age;
    let realValue = age;
    for (let i = 0; i <= 9; i++) {
      let tradingIncrease = Math.ceil(parseFloat(tradeValue) + (parseFloat(tradeValue) * parseFloat(tradingFormulaData)) / 100);
      tradingList.push({ year: i.toString() + t("graphYearTitle"), tradingInvest: tradingIncrease })
      tradeValue = tradingIncrease;
      let realIncrease = Math.ceil(parseFloat(realValue) + (parseFloat(realValue) * parseFloat(realFormulaData)) / 100);
      realEstateList.push({ year: i.toString() + t("graphYearTitle"), realInvest: realIncrease })
      realValue = realIncrease;
    }
    return {
      tradingList: tradingList,
      realEstateList: realEstateList,
      myList: myList
    };
  };

  useEffect(() => {
    let plotGraphList = findInvestment();
    setUserData({
      labels: plotGraphList.myList.map((data) => data.year),
      datasets: [
        {
          label: t("totalInvestmentTitle"),
          data: plotGraphList.myList.map((data) => data.invest),
          backgroundColor: ["#6d64d1aa"],
          fill: true
        }, {
          label: t("cryptoInvestmentTitle"),
          data: plotGraphList.tradingList.map((data) => data.tradingInvest),
          backgroundColor: ["#6d64d1bb"],
          fill: true
        },
        {
          label: t("estateInvestmentTitle"),
          data: plotGraphList.realEstateList.map((data) => data.realInvest),
          backgroundColor: ["#6d64d1c5"],
          fill: true
        },
      ]
    });

    // eslint-disable-next-line
  }, [age, realEstateValue, tradingValue]);
  useEffect(() => {
    // eslint-disable-next-line
  }, [realEstateValue, tradingValue]);
  const updateBackgroundColor = (value) => {
    if (value <= 25) {
      return 0;
    } else if (value <= 50) {
      return 1;
    } else if (value <= 75) {
      return 2;
    } else {
      return 3;
    }
  };
  const highlightedIndex = updateBackgroundColor(realEstateValue);
  return (
    <>
      <div className='invester-section-main'>
        <div className='investerSlider-sec-main'>
          <div className='investSlider'>
            <p>
              € {age}
            </p>
            <Box sx={{ width: 350 }}>
              <Slider
                defaultValue={100000}
                onChange={valueText}
                valueLabelDisplay="auto"
                step={10000}
                marks
                min={100000}
                max={500000}
              />
            </Box>
          </div>
          <div className='backedSlider '>
            <p>
              {valueBack} %
            </p>
            <Box sx={{ width: 350 }}>
              <Slider
                defaultValue={0}
                onChange={valueinvestback}
                valueLabelDisplay="auto"
                step={10}
                marks
                min={0}
                max={100}
              />
            </Box>
          </div>
        </div>
        <div className='riskValue-section'>
          <div className="riskValue-content">
            <h4 className="text-center">{t("propositionRiskTitle")}</h4>
            <div className="riskValue-data">
              <div className="riskValue-profile1">
                <h6>{t("lowRiskTitle")}</h6>
                <p>{t("lowRiskPara")}</p>
              </div>
              <div className="riskValue-profile2">
                <h6>{t("highRiskTitle")}</h6>
                <p>{t("highRiskPara")}</p>
              </div>
            </div>
            <div className='risk-two-side-arrow mb-2'>
              <img src={arrowLogo} alt="arrow-logo"/>
            </div>
            <div className='riskValue-change'>
              <div className='riskValue-change-data'>
                <span className={highlightedIndex === 0 ? 'highlight' : 'unHighlight'}>1</span>
                <span className={highlightedIndex === 1 ? 'highlight' : 'unHighlight'}>2</span>
                <span className={highlightedIndex === 2 ? 'highlight' : 'unHighlight'}>3</span>
                <span className={highlightedIndex === 3 ? 'highlight' : 'unHighlight'}>4</span>
                <span className={highlightedIndex === 4 ? 'highlight' : 'unHighlight'}>5</span>
                <span className={highlightedIndex === 5 ? 'highlight' : 'unHighlight'}>6</span>
                <span className={highlightedIndex === 6 ? 'highlight' : 'unHighlight'}>7</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='Graph-section'>
        <div className='trading-plot1'>
          <InvestmentPie chartDataPie={userDataPie} />
        </div>
        <div className='trading-plot2'>
          <InvestmentGraph chartData={userData} />
        </div>
      </div>
    </>
  );
}
export default TradingGraphs;


import React from 'react';
import WorkMain from './WorkMain';
import "./Work.css";
import AccordionPage from '../Subcomponents/AccordionPage';
import WorkAbout from '../Subcomponents/WorkAbout';
const Work=()=> {
  return (
    <div>
        <div className='work-content-sec'>
          <WorkAbout
          WorkAbouthead="Work main"
          WorkAboutLink1="/"
          WorkAboutLink2="#"
          WorkAboutLink3="/howitworks"
          WorkAboutLinktitle1="Home"
          WorkAboutLinktitle2="Invest"
          WorkAboutLinktitle3="How it works"/>
        </div>
        <WorkMain/>
        <div className='work-content2'>
          <AccordionPage/>
        </div>
    </div>
  )
}
export default Work;
import React, { useState } from "react";
import "./Frame1.css";
import ConditionComp from "../Subcomponents/ConditionComp/ConditionComp";
import Headdemo from "../Subcomponents/Headdemo";
import Footer from "../Subcomponents/HeaderComponents/Footer";
import Phone from "../Subcomponents/Phone";
// import HeaderLogo from "../Subcomponents/HeaderLogo/HeaderLogo";
import Heading from "../Subcomponents/Heading";
// import frame1AboutLogo from "../../assets/images/frame1-about-logo.png";
import ProsperInvest from "../Subcomponents/ProsperInvest/ProsperInvest";
import DiscoverApp from "../Subcomponents/DiscoverApp/DiscoverApp";
import FundCard from "../Subcomponents/FundCard/FundCard";
import fundLogo1 from "../../assets/images/fundCard1.png";
import fundLogo2 from "../../assets/images/fundCard2.png";
import fundLogo3 from "../../assets/images/fundCard3.png";
import { Link } from "react-router-dom";
import LaunchCard from "../Subcomponents/LaunchCard/LaunchCard";
import planeIcon from "../../assets/images/Plane-Icon.png";
import ArrowBtn from "../Subcomponents/ArrowBtn/ArrowBtn";
import PdfViewer from "../Subcomponents/PdfViewer";
import growthLogo from "../../assets/images/frame-data-img-white.png";
import bg1 from "../../assets/images/bg1-4.jpg";
import { useTranslation } from "react-i18next";

const Frame1 = () => {
  const { t,i18n } = useTranslation();
  const pdfBusinessUrl =
    process.env.PUBLIC_URL +
    (i18n.language === "nl" ? "/factsheetdutch.pdf" : "/factsheeteng.pdf");
  const [showFactPDF, setShowFactPDF] = useState(false);
  const handleFactBtnClick = () => {
    setShowFactPDF(true);
    window.open(pdfBusinessUrl, "_blank");
  };
  return (
    <div>
      <ConditionComp />
      <Headdemo />
      <div className="main-header-funds">
        <div className="main-header-funds-logo">
          <img src={bg1} alt="main-logo" />
          <div className="main-header-abs">
            <img src={growthLogo} alt="logo" />
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div className="frame1-about">
          <Heading heading={t("directAboutTitle")} />
          <div className="frame1-about-logo">
            <HeaderLogo HeaderLogo={frame1AboutLogo} />
          </div>
        </div>
      </div> */}
      <div className="frame1-funds">
        <Heading heading={t("directFundsCapital")}/>
        <div className="frame1-fund-data">
          <div className="frame1-fund-content1">
            <FundCard
              fundCardText={t("directCrypto")}
              fundCardLogo={fundLogo1}
              fundCardRoute="/cryptoFund"
            />
          </div>
          <div className="frame1-fund-content2">
            <FundCard
              fundCardText={t("directResidential")}
              fundCardLogo={fundLogo2}
              fundCardRoute="/residential"
            />
          </div>
          <div className="frame1-fund-content3">
            <FundCard
              fundCardText={t("directDevelopment")}
              fundCardLogo={fundLogo3}
              fundCardRoute="/development"
            />
          </div>
        </div>
        <div className="frame-fund-coming text-center">
          <h4>{t("directComingSoon")}</h4>
          <div className="frame-fund-anchor">
            <span>
              <Link onClick={handleFactBtnClick}>{t("directBusinessSheet")}</Link>
              {showFactPDF && <PdfViewer pdfUrl={pdfBusinessUrl} />}
            </span>
          </div>
        </div>
      </div>
      <div className="frame1-launch">
        <div className="frame1-launch-data">
          <div className="frame1-launch-content1">
            <LaunchCard
              launchDetail={t("directStockTitle")}
              launchMainJet={planeIcon}
              launchDate={t("directEstimated")}
            />
          </div>
          <div className="frame1-launch-content2">
            <LaunchCard
              launchDetail={t("directCommercialTitle")}
              launchMainJet={planeIcon}
              launchDate={t("directEstimated")}
            />
          </div>
          <div className="frame1-launch-content3">
            <LaunchCard
              launchDetail={t("directVentureTitle")}
              launchMainJet={planeIcon}
              launchDate={t("directEstimated")}
            />
          </div>
        </div>
      </div>
      <div className="frame1-arrow">
        <div className="frame1-arrow-content1">
          <ArrowBtn arrowAnchor="/howitworks" arrowText={t("startNowTitle")} />
        </div>
      </div>
      <DiscoverApp />
      <ProsperInvest />
      <Footer />
      <Phone />
    </div>
  );
};

export default Frame1;

import Modal from 'react-bootstrap/Modal';
import ContactForms2 from '../Contact/ContactForms2';
import "./popoverstyle.css";
const PopoverModal=(props)=> {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
          <div className='popover-pad'>
              <Modal.Header closeButton>
              </Modal.Header>
              <ContactForms2/>
          </div>
      </Modal>
    );
  }
  export default PopoverModal;
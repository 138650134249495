import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import "./Phone.css";

const Phone= () => {
  return (
    <div className='Phone-main'>
        <a href="tel:(+31) 10 800 54 30">
            <PhoneIcon className='Phone-icon'/>
        </a>
    </div>
  )
}
export default Phone;
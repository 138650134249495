import React from 'react';
import ArrowText from './ArrowText';
import "./Question.css";
import { useTranslation } from 'react-i18next';
const Question = (props) => {
    const { t } = useTranslation();
  return (
    <div className='question-main'>
        <div className='question-content'>
            <h2>
                {props.qHead}
            </h2>
            <h3>
                {props.qHead2}
            </h3>
            <p>
                {props.qPara}
            </p>
        </div>
        <div className='question-direction'> 
            <ArrowText
                  headanchor="/contact"
                  linktext={t("getInTouchTitle") } />
            <span className='qustion-mar'>&nbsp;&nbsp;{props.qcallTitle}</span>
            <a href="tel:(+31) 10 800 54 30"><span className='qustion-mar'>&nbsp;&nbsp;{props.qcallNumber}</span></a>
        </div>
    </div>
  )
}
export default Question;
import React, { useState } from 'react'
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import "./Counter.css";
const CounterPageUP=(props)=> {
    const [counterOn,setCounterOn] = useState(false);
  return (
    <div className='counter-main'>
        <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}>
            <h4>
                {counterOn && <CountUp start={0} end={props.endingStage} duration={2} delay={0}/>}<span>{props.countIcon}</span>
            </h4> 
            <div className='counter-image'>
                <img src={props.counterImage} alt="counter-tag"/>
            </div>
            <h6>
                {props.countheading}
            </h6>   
        </ScrollTrigger>
    </div>
  )
}
export default CounterPageUP;
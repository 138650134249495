import React from "react";
import "./LatestNewsDetail.css";
import Headdemo from "../Headdemo";
import ConditionComp from "../ConditionComp/ConditionComp";
import Footer from "../HeaderComponents/Footer";
import Phone from "../Phone";
import NewsPageCompLogo from "../NewsPageComp/NewsPageCompLogo";
import NewsPageCompTitle from "../NewsPageComp/NewsPageCompTitle";
import NewsPageCompBtn from "../NewsPageComp/NewsPageCompBtn";
import NewsPageCompSubTitle from "../NewsPageComp/NewsPageCompSubTitle";
import NewsPageCompPara from "../NewsPageComp/NewsPageCompPara";
import n1 from "../../../assets/images/n1.png";
import n11 from "../../../assets/images/n1-1.png";
import NewsCompGrowthLogo from "../NewsPageComp/NewsCompGrowthLogo";
import growthLogo from "../../../assets/images/Artboard 95logo-3.png";
import { useTranslation } from "react-i18next";

const LatestNewsOne = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ConditionComp />
      <Headdemo />
      <div className="news-content-width">
        <NewsCompGrowthLogo newsGrowthLogo={growthLogo} />
        <NewsPageCompLogo newsLogo={n1} />
        <NewsPageCompTitle newsTitle={t("news1Title")} />
        <div className="latest-news-btns">
          <div className="latestnew-btn1 ">
            <NewsPageCompBtn
              newsAnchorTitle={t("newsPageButton1")}
              newsAnchor="/residential"
            />
          </div>
          <div className="latestnew-btn2">
            <NewsPageCompBtn newsAnchorTitle={t("fundTitle")} newsAnchor="/funds" />
          </div>
        </div>
        <NewsPageCompSubTitle newsSubTitle={t("newsPage1Title1")} />
        <NewsPageCompPara newsPara={t("newsPage1Para1")} />
        <NewsPageCompSubTitle newsSubTitle={t("newsPage1Title2")} />
        <NewsPageCompPara newsPara={t("newsPage1Para2")} />
        <NewsPageCompSubTitle newsSubTitle={t("newsPage1Title3")} />
        <NewsPageCompPara newsPara={t("newsPage1Para3")} />
        <NewsPageCompLogo newsLogo={n11} />
        <NewsPageCompSubTitle newsSubTitle={t("newsPage1Title4")} />
        <NewsPageCompPara newsPara={t("newsPage1Para4")} />
        <NewsPageCompPara newsPara={t("newsPage1Para5")} />
      </div>
      <Footer />
      <Phone />
    </div>
  );
};

export default LatestNewsOne;

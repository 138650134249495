import React from 'react';
import "./LabelComp.css";

const LabelComp = (props) => {
  return (
    <div className='label-comp'>
        <label htmlFor={props.htmllabel}>{props.labelContent}</label>
    </div>
 )
}
export default LabelComp;
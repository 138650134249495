import React from 'react';
import './logo.css'
import logo from '../../assets/images/logo.png'
const Logo = () => {
  return (
    <div className='image-logo'>
        <img src={logo} alt="image_logo"/>
    </div>
  )
}

export default Logo;
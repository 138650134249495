import React from "react";
import "./NewsStyles.css";

const NewsCompGrowthLogo = (props) => {
  return (
    <div className="news-growth-logo">
      <img src={props.newsGrowthLogo} alt="news-logo" />
    </div>
  );
};

export default NewsCompGrowthLogo;
